import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialCategorys } from '../../../slice/materialSlice/MaterialCategorySlice';
import { getMaterialStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import { addSwatchBrand, getMaterialBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { MaterialBrandModel, SwatchBrandModel } from '../../../Model/material/MaterialBrandModel';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';

const GetSwatchBrand = () => {
  const dispatch = useDispatch();

  const getMaterialCategory = useSelector(getMaterialCategorys) as MaterialCategoryModel[];
  const getMaterialBrands = useSelector(getMaterialBrand) as MaterialBrandModel[];

  useEffect(() => {
   
    const allSwatchBrand: SwatchBrandModel[] = [];
    if  (getMaterialBrands &&
      getMaterialBrands.length>0 && 
      getMaterialCategory &&
      getMaterialCategory.length>0) {
      getMaterialBrands.forEach((item) => {
        const category = getMaterialCategory.find(cat => cat.id === item.material_category_id);
          
        if (category) {
          const data: SwatchBrandModel = {
            id: item.id,
            category: category,
            title: item.title,
            slug: item.slug,
            description: item.description,
            sort_order: item.sort_order,
            status: item.status
          }

          allSwatchBrand.push(data);
        }
      });
    }

    if (allSwatchBrand.length > 0) {
      dispatch(addSwatchBrand(allSwatchBrand));
    }
  }, [getMaterialBrands, getMaterialCategory, dispatch]);

  return (
    <div></div>
  );
}

export default GetSwatchBrand;
