import React, { useEffect, useState } from 'react'
import AddGroupModel from './AddGroupModel'
import { useDispatch, useSelector } from 'react-redux'
import { getAddSegmentGroup, resetAddSegment } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice'

const AddNewSegmentHome = () => {
    const dispatch= useDispatch()
    const getAddSegmentGroups= useSelector(getAddSegmentGroup)
const [ isSegmentModal, setIsSegmentModal]= useState<boolean>(false)
const [segmentGroupName, setSegmentGroupName ]= useState<string|null>(null)
useEffect(()=>{
  if(getAddSegmentGroups &&
    getAddSegmentGroups.groupName 
   ){
    setSegmentGroupName(getAddSegmentGroups.groupName)
   
  }
  
  if(getAddSegmentGroups &&
      getAddSegmentGroups.isOpenModel){
        setIsSegmentModal(getAddSegmentGroups.isOpenModel)
  }
  else{
    setIsSegmentModal(false)
  }
},[getAddSegmentGroups])


  

    const handleCloseModal=()=>{
        dispatch(resetAddSegment())
    }

    const handleOk=()=>{
      console.log("ok")
    }
  return (
    <div>
        
       {isSegmentModal &&
      
        <AddGroupModel
        isSegmentModal={isSegmentModal}
        // segmentGroupName={segmentGroupName}
        handleClose={handleCloseModal}
        ok={handleOk}
        selectedSegment={false}
        />}
    </div>
  )
}

export default AddNewSegmentHome