import React from 'react'
import ShowProjects from '../../module/projectPage/ProjectHome'

const Projects = () => {
  return (
    <div className='p-0'>
        <ShowProjects/>
    </div>
  )
}

export default Projects