import React from 'react'
import Header from '../header/Header'
import Projects from './projects/Projects'

const ProfileHome = () => {
  return (
    <div>
        {/* <Header/> */}
        <Projects/>
    </div>
  )
}

export default ProfileHome


