
import React, { useEffect, useState } from 'react'
import "./Tools.scss";
import { useDispatch, useSelector } from 'react-redux';
import { addRegeneration } from '../../../../slice/modelData/ModelDataSlice';
import { changeSegmentTab, startDownload, switchToOutline } from '../../../../slice/tabControl/TabControlSlice';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { startDownlaod } from '../../../../slice/canvas/updatevalue/UpdateValueSlice';
import { startUpdateJob } from '../../../../slice/canvas/masterArray/MasterArraySlice';

import CommentHome from '../comment/CommentHome';
import { getComment, openCommentTab, resetCanvasComment } from '../../../../slice/projectCommet/ProjectCommentSlice';
import { addSegment } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice';
import ZoomCanvas from '../zoomcanvas/ZoomCanvas';

type Props = {

}


const Tools = () => {
  const [showicon, setshowicon] = useState(true);
  const [isComment, setIsComment] = useState<boolean>(false);
  const [isProjectLayer, setIsProjectLayer] = useState(true);
  const [isNoiseReduction, setIsNoiseReduction] = useState(false);
  const [isOutLine, setIsOutLine] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isDimension, setIsDimension] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const [isChat, setIsChat] = useState<boolean>(false)
  const dispatch = useDispatch()
  const getComments = useSelector(getComment);
  const navigate = useNavigate()
  const handleShow = () => {
    setshowicon(!showicon)
  }


  const handleRegeneration = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(addRegeneration(true))
  }

  const handleOutline = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(switchToOutline("outline"))
    setIsOutLine(true)
    setIsProjectLayer(false)
    setIsCompare(false)
    setIsDimension(false)
  }

  const handleHoverLayer = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(switchToOutline("segment"))
    setIsOutLine(false)
    setIsCompare(false)
    setIsProjectLayer(true)
    setIsDimension(false)
  }


  const handleSwitchChange = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(switchToOutline("compare"))
    setIsOutLine(false)
    setIsProjectLayer(false)
    setIsCompare(true)
    setIsDimension(false)
  }

  const handleDimension = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(switchToOutline("dimension"))
    setIsDimension(true)
    setIsOutLine(false)
    setIsProjectLayer(false)
    setIsCompare(false)

  }


  const captureScreenshot = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));

    dispatch(startDownlaod())
  }


  const handleSaveToDB = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(startUpdateJob())

  }

  useEffect(() => {
    if (getComments.isCommentTab) {

    } else if (!getComments.isCommentTab) {
      setIsComment(false)
    }
  }, [getComments])
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{ [key: number]: number | null }>({});

  // Function to handle submenu click
  const handleSubmenuClick = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
  };

  const handleChat = (parentIndex: number, submenuIndex: number) => {

    if (!getComments.isCommentTab) {
      setActiveSubmenuIndex((prevState) => ({
        ...prevState,
        [parentIndex]: submenuIndex,
      }));
      dispatch(openCommentTab())
    } else {
      setActiveSubmenuIndex({})
      dispatch(resetCanvasComment())
    }


  }


  // add segment 
  const handleAddSegment = (parentIndex: number, submenuIndex: number) => {
    console.log("add segment")
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    dispatch(addSegment({
      isOpenModel: true,
      // groupName: eachSegment.name
    }))
  }
  return (
    <>




      <ZoomCanvas />





      <div className="select-pallet-toolbars">
        <div className='tool-show-hide-icon' onClick={handleShow}>
          <i className="bi bi-eye-fill"></i>
        </div>

        {showicon &&
          <div className='pro-tool-wrapper-icons'>
            <div className="tool-wrapper">

              <div className="select-tool-project">
                <div
                  className={activeSubmenuIndex[0] === 0 ? 'active-icon' : ''}
                  onClick={() => handleHoverLayer(0, 0)}

                >
                  <i className="bi bi-hand-index"></i>
                </div>

                <div
                  className={activeSubmenuIndex[0] === 1 ? 'active-icon' : ''}
                  onClick={() => handleAddSegment(0, 1)} >
                  <i className="bi bi-bounding-box-circles"></i>
                </div>

                <div
                  className={activeSubmenuIndex[0] === 2 ? 'active-icon' : ''}
                  // onClick={() => handleSubmenuClick(0, 1)}
                  onClick={() => handleChat(0, 2)}
                >
                  {/* <CommentButton/> */}
                  <i className="bi bi-chat"></i>
                </div>



                <div
                  className={activeSubmenuIndex[0] === 3 ? 'active-icon' : ''}
                  onClick={() => handleSubmenuClick(0, 3)}

                >
                  <i className="bi bi-arrow-counterclockwise"></i>
                </div>



                <div
                  className={activeSubmenuIndex[0] === 4 ? 'active-icon' : ''}
                  onClick={() => handleSubmenuClick(0, 4)}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </div>


              </div>

              <div className="select-tool-project">


                {/* Canvas Mode */}
                {/* <div
                  className={/activeSubmenuIndex[0] === 4 ? 'active-icon' : ''}
                  onClick={() => handleSubmenuClick(0, 4)}

                  >
                    <i
                      className="bi bi-intersect"
                      onClick={handleHoverLayer}
                    ></i>
                  </div> */}


                {/* Outlines */}

                <div
                  className={activeSubmenuIndex[0] === 5 ? 'active-icon' : ''}
                  // onClick={() => handleSubmenuClick(0, 5)}
                  onClick={() => handleOutline(0, 5)}
                >
                  <i className="bi bi-border"

                  ></i>
                </div>

                {/* Compare */}


                <div
                  className={activeSubmenuIndex[0] === 6 ? 'active-icon' : ''}
                  // onClick={() => handleSubmenuClick(0, 6)}
                  onClick={() => handleSwitchChange(0, 6)}
                >
                  <i
                    className="bi bi-chevron-bar-contract"
                  //onClick={handleSwitchChange}
                  ></i>
                </div>
                {/* Dimention */}

                <div
                  className={activeSubmenuIndex[0] === 7 ? 'active-icon' : ''}
                  onClick={() => handleDimension(0, 7)}
                >
                  <i className="bi bi-rulers"></i>
                </div>


                <div

                  className={activeSubmenuIndex[0] === 8 ? 'active-icon' : ''}
                  onClick={() => handleRegeneration(0, 8)}
                >
                  <i
                    //  onClick={handleRegeneration}
                    className="bi bi-repeat"></i>
                </div>

                <div
                  className={`save-icons ${activeSubmenuIndex[0] === 9 ? 'active-icon' : ''}`}
                  onClick={() => handleSaveToDB(0, 9)}
                >
                  <svg
                    // onClick={handleSaveToDB}
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16">
                    <path d="M11 2H9v3h2z" />
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                  </svg>
                </div>

                <div className={activeSubmenuIndex[0] === 10 ? 'active-icon' : ''}
                  onClick={() => captureScreenshot(0, 10)}
                >
                  <i
                    //onClick={captureScreenshot}
                    className="bi bi-download" ></i>
                </div>

              </div>

              {/* <div className="select-tool-project">
{/* Regeneration */}



              {/* <div
                className={activeSubmenuIndex[0] === 11 ? 'active-icon' : ''}
                onClick={() => handleSubmenuClick(0, 11)}
              >
                <i className="bi bi-file-earmark-ppt" ></i>
                {/* onClick={handleReport} */}
              {/* </div> */} 

              
              {/* <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Information</Tooltip>}> */}

              {/* <div
                className={activeSubmenuIndex[0] === 12 ? 'active-icon' : ''}
                onClick={() => handleSubmenuClick(0, 12)}
              >
                <i className="bi bi-info-lg" ></i>

              </div> */}
              {/* </OverlayTrigger> */}

              {/* <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Noise Reduction</Tooltip>}> */}
              {/* <div
                className={activeSubmenuIndex[0] === 13 ? 'active-icon' : ''}
                onClick={() => handleSubmenuClick(0, 13)}
              >
                <i className="bi bi-eraser"
                // onClick={handleNoise}
                ></i>
              </div> */}


              {/* </OverlayTrigger> */}


              {/* <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Conneted Shape</Tooltip>}>
<div
  // onClick={handleOverLap}
  className={isCompare ? "active-icon" : ""}>
  <i className="bi bi-columns"></i>
</div>
</OverlayTrigger> */}

              {/* 
<OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Door height</Tooltip>}>
<div
  // onClick={handleDoorSize}
  className={isCompare ? "active-icon" : ""}>
  <i className="bi bi-door-closed-fill"></i>
</div>
</OverlayTrigger> */}
              {/* <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Full Screen</Tooltip>}>
<div >
  <i className="bi bi-arrows-fullscreen"
  ></i>
</div>
</OverlayTrigger> */}


              {/* </div>  */}

            </div>
          </div>
        }


      </div>

      {/*  chat home */}
      {/* <CommentHome
     
      /> */}
    </>
  )
}

export default Tools
