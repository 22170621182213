import React from 'react'
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import "./Compare.css";
import OriginalCanvas from './OriginalCanvas';
import SelectedSwatchCanvas from './SelectedSwatchCanvas';
const CompareHome = () => {
  return (
    <div>
      {/* <h3>Hello</h3> */}
         <div
          className='compare-image'>
      <ImgComparisonSlider className='comapare-img' 
    //   hover="hover"
      >
    

        <figure slot="first" className="before">
          <OriginalCanvas/>
 {/* { project_image&&  <img width="100%" src={project_image}></img>} */}
    <figcaption>Before</figcaption>
  </figure>
  <figure slot="second" className="after">
    {/* <img width="100%" src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/before_7e38401ca2.jpg"></img>
     */}
    < SelectedSwatchCanvas/>
    <figcaption>After</figcaption>
  </figure>
    </ImgComparisonSlider>


    </div>
    </div>
  )
}

export default CompareHome