import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../../page/login/LoginPage';

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
     
    </Routes>
  );
};

export default ProjectRoutes;
