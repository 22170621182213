import React, { useEffect, useState } from 'react'
import GetMaterials from './GetMaterials'

import GetAllMaterialCaterogy from './GetAllMaterialCaterogy'

import { useSelector } from 'react-redux'
import { getMaterial } from '../../slice/materialSlice/MaterialSlice'
import GetAllMaetrial_brand_style from './style/GetAllMaetrial_brand_style'
import GetMaterialBrand from './brand/GetMaterialBrand'
import GetSwatchStyle from './style/GetSwatchStyle'
import GetSwatchBrand from './brand/GetSwatchBrand'
import AllSwatches from './swatches/AllSwatches'
import GetAllSegments from '../brandPage/Segment/api/GetAllSegments'

const GetAllMaterialsHome = () => {
   


  return (
    <div>

      {/* get ALL material */}
      { <GetMaterials/>}

          {/*  all style with brand and swatches */}
        <GetAllMaetrial_brand_style/>

      {/*  all category */}
        <GetAllMaterialCaterogy/>

          {/*  allBrand */}
        <GetMaterialBrand/>

        {/*  all style with swatch */}
        <GetSwatchStyle/>

    {/* all brands with swatches */}
        <GetSwatchBrand/>

      {/* all swatches */}
        <AllSwatches/>

        {/* all Segment */}
        <GetAllSegments/> 
    </div>
  )
}

export default GetAllMaterialsHome