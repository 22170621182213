// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-table-category .ant-card-body{padding: 14px 16px!important;}
.breadcrumb-link{    display: flex;
    align-items: center;
    justify-content: end;
}

.search-bar .ant-input-outlined{ width: 220px;     float: inline-start;}
.table-input{align-items: end; margin-bottom: 15px;}
.action-icon-table i{ font-size: 16px!important;}
.action{align-items: center;}`, "",{"version":3,"sources":["webpack://./src/module/brandPage/category/CategoryTable.css"],"names":[],"mappings":"AAAA,oCAAoC,4BAA4B,CAAC;AACjE,qBAAqB,aAAa;IAC9B,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA,iCAAiC,YAAY,MAAM,mBAAmB,CAAC;AACvE,aAAa,gBAAgB,EAAE,mBAAmB,CAAC;AACnD,sBAAsB,yBAAyB,CAAC;AAChD,QAAQ,mBAAmB,CAAC","sourcesContent":[".card-table-category .ant-card-body{padding: 14px 16px!important;}\n.breadcrumb-link{    display: flex;\n    align-items: center;\n    justify-content: end;\n}\n\n.search-bar .ant-input-outlined{ width: 220px;     float: inline-start;}\n.table-input{align-items: end; margin-bottom: 15px;}\n.action-icon-table i{ font-size: 16px!important;}\n.action{align-items: center;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
