// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edit-project-section{ margin: 0 auto;}
.edit-project{margin-top: 30px; padding-top: 30px; padding-bottom: 30px;}
.back-icon{text-align: left; 
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 84px;
    height: 26px;
    margin-top: 30px;
}

    .back-icon i{font-size: 40px; color: #6d455a;}
    .back-icon span{ color: #6d455a;font-family: "Poppins", sans-serif; }
  
    .edit-job-from .back-icon{ display: none;}
    .edit-job-from .card-edit{padding: 0; margin: 0!important;}
    
`, "",{"version":3,"sources":["webpack://./src/module/brandPage/jobs/EditProject.css"],"names":[],"mappings":"AAAA,uBAAuB,cAAc,CAAC;AACtC,cAAc,gBAAgB,EAAE,iBAAiB,EAAE,oBAAoB,CAAC;AACxE,WAAW,gBAAgB;IACvB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;IAEI,aAAa,eAAe,EAAE,cAAc,CAAC;IAC7C,iBAAiB,cAAc,CAAC,kCAAkC,EAAE;;IAEpE,2BAA2B,aAAa,CAAC;IACzC,0BAA0B,UAAU,EAAE,mBAAmB,CAAC","sourcesContent":[".Edit-project-section{ margin: 0 auto;}\n.edit-project{margin-top: 30px; padding-top: 30px; padding-bottom: 30px;}\n.back-icon{text-align: left; \n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    width: 84px;\n    height: 26px;\n    margin-top: 30px;\n}\n\n    .back-icon i{font-size: 40px; color: #6d455a;}\n    .back-icon span{ color: #6d455a;font-family: \"Poppins\", sans-serif; }\n  \n    .edit-job-from .back-icon{ display: none;}\n    .edit-job-from .card-edit{padding: 0; margin: 0!important;}\n    \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
