import axios from "axios";

import { JobModel, UpdateJobModel, UpdateJobModels } from "../../Model/Job/JobModel";



 const serverApi =process.env.REACT_APP_SERVER_URL
// const serverApi ="https://ztrbpzguq1.execute-api.us-east-2.amazonaws.com/dev"

// export function GetallProject() {

//    // return axios.get(`${serverApi}/projects`,)
//     return axios.get(`${serverApi}/customer/project`,)
//         .then(response => {
//             console.log("project", response)
//             return response
//         }).catch(error => {
//             throw error;
//         });


// }

// add job into job collection
export function addUserJob(data:JobModel){
    return axios.post(`${serverApi}/job/add`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}


// update job into job collection
export function UpdateUserJob(data:UpdateJobModels){
    return axios.post(`${serverApi}/job-update`,data)
    .then(response => {
      
        return response
    }).catch(error => {
        throw error;
    });
}

// get user Job
export function GetUsersJob(jobId:number){
    const data={
        jobId:jobId
    }
    return axios.post(`${serverApi}/job/get`,data)
    .then(response => {
        
        return response
    }).catch(error => {
        throw error;
    });
}


// get All jobs
export function GetJobsAll(){
 
    return axios.get(`${serverApi}/job/get-all`)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

export function UpdateBase64(base64Image:string, jobId:number){
 
    const data={
        jobId:jobId,
        base_64:base64Image
    }
    return axios.post(`${serverApi}/job-update-base64`, data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}
