// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-body-wrapper {
  height: 100%;
}
.main-body-wrapper main {
  padding-top: 61px;
  min-height: calc(100% - 61px);
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,iBAAA;EACA,6BAAA;AAER","sourcesContent":[".main-body-wrapper{\n    height: 100%;\n    main {\n        padding-top: 61px;\n        min-height: calc(100% - 61px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
