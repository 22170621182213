import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./LayerPage.scss";
import AnnotationList from '../../canavas/newSegment/addNewSegment/AnnotationList';
import ReactDOMServer from "react-dom/server";
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';
import { useSelector, useDispatch } from 'react-redux';
import { JobSegmentModel, Segment } from '../../../Model/Job/JobModel';
import { JobSegmentationModel } from '../../../Model/masterArray/MasterArrayModel';
import { startSegHoverEffect, stopSegHoverEffect } from '../../../slice/canvas/masterArray/MasterArraySlice';

const LayerPage = () => {
  const iconRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<any>(null);
  const getSegregateSegments = useSelector(getSegregateSegment);
  const dispatch = useDispatch();
 const [segregateSegment,setSegregateSegment]=useState<JobSegmentModel[]>([])
  useEffect(()=>{
    if(getSegregateSegments.length>0){
      setSegregateSegment(getSegregateSegments)
    }
  },[getSegregateSegments])
  useEffect(() => {
    if (iconRef.current) {
      const bootstrap = require("bootstrap"); // Import Bootstrap JS

      popoverRef.current = new bootstrap.Popover(iconRef.current, {
        html: true, // Allow HTML in the popover
        content: ReactDOMServer.renderToString(<AnnotationList />), // Render JSX to HTML string
        placement: "right", // Position popover on the right
        customClass: "seg-popover layer-popover", // Custom class for this specific popover
        trigger: 'manual', // Use manual trigger to control showing and hiding
      });
    }

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element; // Assert target as Element

      if (iconRef.current && !iconRef.current.contains(target)) {
       // popoverRef.current.hide(); // Hide popover if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (popoverRef.current) {
       // popoverRef.current.dispose(); // Clean up popover on unmount
      }
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener
    };
  }, []);

  // Function to handle icon click
  const handleIconClick = () => {
    if (popoverRef.current) {
      popoverRef.current.toggle(); // Toggle popover on icon click
    }
  };

  const onDragEnd = (result: any) => {
    console.log(result)
    if (!result.destination) {
      return;
    }

    const items = Array.from(segregateSegment);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

   // dispatch(setSegregateSegment(items));
  };

  const handLeMouseHover=(data:JobSegmentationModel)=>{
   // console.log(data)
    dispatch(startSegHoverEffect({
     seg:[data]

    }))
 }


 const handleMouseOut=()=>{
  dispatch(stopSegHoverEffect())
}

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="layers">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {segregateSegment && segregateSegment.length > 0 && (
              segregateSegment.map((item, index) => (
                Object.keys(item).map((key) => {
                  const jobDetail = item[key] as JobSegmentationModel;
                  const segshortName = jobDetail?.details?.seg_short;
                  const segName = jobDetail?.details?.seg_type;
                  const name=jobDetail?.details?.seg_name
                   const data={
                    segName:name,
                    name:segshortName,
                    details:jobDetail?.details
                   }
                  return (
                    <Draggable key={`item-${index}-${key}`} draggableId={`item-${index}-${key}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="d-flex align-items-center justify-content-between m-2 border px-2 rounded-1 layer-section"
                          style={{
                            ...provided.draggableProps.style,
                            transition: snapshot.isDragging ? 'none' : 'transform 0.3s ease-out',
                          }}
                        >
                          <div className="d-flex align-items-center"
                         onMouseEnter={()=>handLeMouseHover(data)}
                          onMouseLeave={handleMouseOut}
                          >
                            <span className="drag-icon me-2" {...provided.dragHandleProps}>
                              <i className="bi bi-grip-vertical"></i>
                            </span>
                            <span
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: `red`,
                                borderRadius: "50%",
                                display: "inline-block",
                                marginRight: "20px",
                              }}
                            ></span>
                            <span className='fs-6 fw-semibold'>{segName}-{segshortName}</span>
                          </div>
                          
                          <i
                            className="bi bi-three-dots-vertical"
                            ref={iconRef}
                            data-bs-toggle="popover"
                            onClick={handleIconClick}
                          ></i>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default LayerPage;
