import React, { useEffect, useState } from 'react'
import { Button } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { ProjectModel } from '../../../Model/project/ProjectModel'

import "./ShowListOfProjects.css"
import "./ListOfProject.scss"
import GetUserJob from '../jobs/GetUserJob'
import { getUserJobData, searchJobBase64 } from '../../../slice/userJobSlice/UserJobSlice'
import { useDispatch, useSelector } from 'react-redux'
import GetJobbase64 from '../../brandPage/jobs/jobapi/GetJobbase64'
// import { getLoading, startLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
// import Loading from '../../loading/Loading'
import { addNewProject } from '../../../slice/user/UserProject'
import Base64Image from '../base64Image/Base64Image'
import ActionProject from '../actionProject/ActionProject'
import DeletEProjectById from '../actionProject/deleteProject/DeletEProjectById'
import DeleteModal from '../actionProject/deleteProject/DeleteModal'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import GetBase64FromApi from '../base64Image/GetBase64FromApi'


const AssetImage = process.env.REACT_APP_ASSERT_Image
const masterImage = `${AssetImage}/images/no-image-.jpg`
const urlpath = process.env.REACT_APP_Project_Image
type Props = {
  projectItem: ProjectModel
}
const ListOfProject = ({ projectItem }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getUserJobDatas = useSelector(getUserJobData)
  const [jobId, setJobId] = useState<number | null | undefined>(null)
  const [isJobImage, setIsJobImage] = useState<boolean>(false)
  //const [imageUrl, setImageUrl] = useState<string | null>()
  // const [projectId, setProjectId] = useState<number | null>(null)

  const [isDeletModal, setIsDeleteModal] = useState<boolean>(false)
  const handleJob = (item: ProjectModel) => {

    //// dispatch(startLoading())
    dispatch(addNewProject(item))
    let jobid:number=0
    let imageUrl:string="";
    let projectId:number=0;
    if (item &&
      item.jobs &&
      item.jobs.length > 0 &&
      item.image && item.id) {
        projectId=item.id
      // setProjectId(item.id)
     // setImageUrl(item.image)
      imageUrl=item.image
      item.jobs.map(item => {
        jobid=item
        //setJobId(item)
      })
    }
    if(jobid!=0 && projectId!=0 && imageUrl!=""){
      dispatch(searchJobBase64({
        imagePath:imageUrl,
        projectId:projectId,
        jobId:jobid
       }))
       
      navigate(`/project/view/${jobid}`)
    }
    

  }
  const [isJob, setIsJob] = useState<boolean>(false)

  const handleResetJob = (mess: string) => {
    
    if (mess === "success") {
        // navigate(`/project/view/${jobId}`)
    // } else if(mess==="required"){
      setIsJobImage(true)
    }
    else {
      dispatch(addMessage({
        isShow:true,
        mess:"Some thing went wrong",
         toastType:"Error"
       }))
      // alert("Some thing went wrong")
    }
    //setJobId(null) 
  }


  const handleResetImageUrl = (mess: boolean) => {
    setJobId(null)
   // setImageUrl(null)
    setIsJobImage(false)
    // dispatch(stopLoading())
    if (mess) {
      navigate(`/project/view/${jobId}`)
    } else {
      
      dispatch(addMessage({
        isShow:true,
        mess:"Error in getting base64 image",
         toastType:"Error"
       }))
      //alert("Error in getting base64 image")
    }

  }


 

  



  

  return (
    <div className='col-12 col-md-4'>
      <div className="card shadow-lg rounded-3 border-0">
        {projectItem &&
          projectItem.image &&
          projectItem.compressImage &&
          projectItem.id
          ? (
            <div className="media-images rounded-top-3 overflow-hidden"
              key={projectItem.id}
              onClick={() => handleJob(projectItem)}>
              <img
                src={`${urlpath}/${projectItem.id}/${projectItem.compressImage}`}
                alt={projectItem.name}
                key={projectItem.id}
              />
            </div>
          ) : (
            <div className="media-images rounded-top-3 overflow-hidden">
              <img className="master-images-1" src={masterImage} alt="masterImage" />
            </div>
          )}
        <div className="card-content">
          <div className="job-content p-3 d-flex align-items-center justify-content-between">
            <Button className='job-project-title fs-5 fw-medium p-0 border-0 bg-white'
              variant="solid"
              colorScheme="blue"
              size="md"
              borderRadius="md"
              onClick={() => handleJob(projectItem)}
            // key={image?.attributes?.jobs?.data[0]?.id}
            // onMouseEnter={handleRemoveOption}
            >
              <span  className="create-project-icon" >
                {projectItem.name}-{projectItem.id}
              </span>
            </Button>

            <div className='d-flex align-items-center gap-1 project-action'>
                {/* Project Action */}
          {  projectItem.id &&
          <ActionProject
              projectId={projectItem.id}
            />}

            </div>
          </div>
        </div>



      </div>


      {/*  get user job */}

      {/* {
        jobId != null &&
        <GetUserJob
          jobId={jobId}
          resetJob={handleResetJob}
        />} */}


        {/* get base64 through Api */}
       {/* {isJobImage &&
       imageUrl &&
       projectId &&
        <GetBase64FromApi
        url={imageUrl}
        projectId={projectId}
        resetCallApi={handleResetImageUrl}
        />} */}

      {/* {isJobImage && 
        imageUrl &&
        <GetJobbase64
          resetImageUrl={handleResetImageUrl}
          imageUrl={imageUrl}
        />} */}

      {/* {
        isJobImage &&
        imageUrl &&
        projectId &&
        <Base64Image
          imageUrl={imageUrl}
          resetbase64={handleResetImageUrl}
          projectId={projectId}

        />
      } */}




      {/*  delete modal */}

     

      {/*  delere Api  */}

    
    </div>
    // </div>
  )
}

export default ListOfProject  

