import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSwatchStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import { SwatchStyleModel } from '../../../Model/material/MaterialStyleModel';
import { addSwathes, getMaterial } from '../../../slice/materialSlice/MaterialSlice';
import { MaterialModel, SwatchesModel } from '../../../Model/material/MaterialModel';

const AllSwatches: React.FC = () => {
  const getSwatchStyles = useSelector(getSwatchStyle) as SwatchStyleModel[];
  const getMaterials = useSelector(getMaterial) as MaterialModel[];
  const [allSwatches, setAllSwatches] = useState<SwatchesModel[]>([]);
    const dispatch= useDispatch()
  useEffect(() => {
     
    if (getMaterials &&
       getMaterials.length > 0 &&
       getSwatchStyles &&
        getSwatchStyles.length > 0) {

      
      const swatches: SwatchesModel[] = getMaterials.reduce((acc, item) => {
        const brand = getSwatchStyles.find(b => b.id === item.material_brand_style_id);
    
        if (brand) {
          acc.push({
            id: item.id,
            user_id: item.user_id,
            role_id: item.role_id,
            style_brand_category: brand,
            title: item.title,
            description: item.description,
            photo: item.photo,
            photo_svg:item?.photo_svg,
            is_admin: item.is_admin,
            finish_needed: item.finish_needed,
           // suggested_swatches: item?.suggested_swatches??0,
            manufacturer_request: item.manufacturer_request,
            manufacturer_request_note: item.manufacturer_request_note,
            status: item.status,
            is_featured:item.is_featured,
            bucket_path:item.bucket_path
           // created: item.created??"",
            //modified: item.modified,
          });
        }
        return acc;
      }, [] as SwatchesModel[]);

      setAllSwatches(swatches);

    
    }
  }, [getMaterials, getSwatchStyles]);

  useEffect(()=>{
    if(allSwatches && allSwatches.length>0){
        dispatch(addSwathes(allSwatches))
    }
  },[allSwatches])
  return (
    <div>
      
    </div>
  );
};

export default AllSwatches;
