import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addHoverGroup, addNoHoverGroup, getMasterArray, getUpdateIndexing, resetHoverGrp, resetNoHoverGroup, resetUpdateIndexing } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import {
  GroupMasterSegment,
  MasterArrayModel,
  MasterModel
} from '../../../../Model/masterArray/MasterArrayModel'
import {
  changeSegmentTab,
  getTabControl
} from '../../../../slice/tabControl/TabControlSlice'
import {
  addSelectedSegment,
  resetSelectedSegment
} from '../../../../slice/userJobSlice/UserJobSlice'
import '../LeftSections.scss'
// import './LeftSections.scss'
import EachSeg from '../EachSegments/EachSeg'

import { ReactSVG } from 'react-svg'
import { getCanvasControl } from '../../../../slice/canvas/masterArray/CanvasControlSlice'
import AddSegment from './AddSegment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SegmentBaseSwatch from '../segmentbasedSwatch/SegmentBaseSwatch'

const Segments = () => {
  // const [isShareLink, setIsShareLink] = useState(false)
  const [isMEdiaLibrary, setIsMediaLibrary] = useState(false)
  const [allSegMaster, setAllSegMaster] = useState<MasterArrayModel[]>([])
  const [eachSegment, setEachSegment] = useState<MasterArrayModel | null>(null)
  const [masterSegName, setMasterName] = useState<string | null>(null)
  const [controlPannel, setControlPannel] = useState<string>()
  const getMasterArrays = useSelector(getMasterArray)
  const getTabControls = useSelector(getTabControl)
  const dispatch = useDispatch()
  const getCanvasControls = useSelector(getCanvasControl)
  const getUpdateIndexings= useSelector(getUpdateIndexing)
  // Update tab panel
  useEffect(() => {
    if (getTabControls) {
      setControlPannel(getTabControls)
    }
  }, [getTabControls])

  // Update master array
  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      setAllSegMaster(getMasterArrays.allSeg)
    }else {
      setAllSegMaster([])
      setEachSegment(null)
    
    }
  }, [getMasterArrays])

  // show first segment Swatch e.g wall
  const isFirstSeg = useRef(true)
  useEffect(() => {
    
    if (allSegMaster && 
      allSegMaster.length > 0  &&
       isFirstSeg.current) {
     
       isFirstSeg.current = false
      const name = allSegMaster[0].name
      if(name){
        setMasterName(name || '')
        UpdateEachSegmentGroupName(name)
      }
     
              // if (allSegMaster[0]?.segmentation) {
              //   setEachSegment(allSegMaster[0])
              // }

    } else if (allSegMaster && 
      allSegMaster.length==0){
        setMasterName(null)
      }



     if(getCanvasControls &&
      getCanvasControls.groupName &&
     
      allSegMaster && allSegMaster.length>0
    ) {
      
      setMasterName(getCanvasControls.groupName)
      UpdateEachSegmentGroupName(getCanvasControls.groupName)
      // const selectedmasterArray= allSegMaster.find(item=>item.name===getCanvasControls.groupName)

      //  if(selectedmasterArray && 
      //   selectedmasterArray.segmentation && 
      //   selectedmasterArray.segmentation.length>0){
      //     setEachSegment(selectedmasterArray)
      //     dispatch(addSelectedSegment(selectedmasterArray.segmentation))

      //   }

    }
  }, [allSegMaster,getCanvasControls])


  const UpdateEachSegmentGroupName=(groupName:string)=>{
     if(getMasterArrays && 
      getMasterArrays.allSeg &&
       getMasterArrays.allSeg.length>0){
        
       // setAllSegMaster(getMasterArrays.allSeg)
        const group=getMasterArrays.allSeg.find(item=>item.name==groupName);
        setMasterName(groupName)
        if(group && group.segmentation ){
          setEachSegment(group)
          dispatch(addSelectedSegment(group.segmentation ))
        }

        
     }

  }
  // On click tab
  const handleEachSegment = (data: MasterArrayModel) => {
    dispatch(resetSelectedSegment())

    setEachSegment(data || {})
    if (data.name) {
      setMasterName(data.name)
    }
    if (data &&
       data.segmentation && 
       data.segmentation.length>0) {
    
      dispatch(addSelectedSegment(data.segmentation))
    }
  }

  const handleshowMedia = () => {
    setIsMediaLibrary(true)
  }

  // const handleShareLink = () => {
  //   setIsShareLink(true)
  // }

  // const handleCloseShareLink = () => {
  //   setIsShareLink(false)
  // }

  /// update the each segment after delete
  useEffect(()=>{
    
    if(getUpdateIndexings && 
      getUpdateIndexings.isUpdate &&
       getUpdateIndexings.groupName 
      
    ){
      UpdateEachSegmentGroupName(getUpdateIndexings.groupName )
      // const findGroup= getMasterArrays.allSeg.find(item=>item.name=== getUpdateIndexings.groupName)
      // if(findGroup){
      //   setMasterName(getUpdateIndexings.groupName)
      //   setEachSegment(findGroup)
      // dispatch(addSelectedSegment( findGroup.segmentation))
      
      // }
      
    }

  },[getUpdateIndexings,getMasterArrays])


  // hover over the group
  const handleHoverGroup=(groupName:string)=>{
    // console.log("hover",groupName)
    dispatch(resetNoHoverGroup())
      dispatch(addHoverGroup(groupName))
  }

  const handleRemoveHover=(groupName:string)=>{
    // console.log("handleRemoveHover", groupName)
    dispatch(resetHoverGrp())
    dispatch(addNoHoverGroup(groupName))
   // dispatch(resetHoverGrp())
  }
  return (
    <>
      <div className='d-flex align-items-start' key="segments">
        <ul
          className='nav nav-pills flex-column left-panel-menu  p-2'
          id='myTab'
          role='tablist'
        >
          {allSegMaster &&
            allSegMaster.length > 0 &&
            allSegMaster.map((item: MasterArrayModel, index: number) => {
              const path = 'https://dzinlystrapi.s3.us-east-2.amazonaws.com'
             
              return (
                <>
                <li className={`nav-item dzi-${item.name}` } role='presentation' key={item.id}
                // style={{border:`1px solid ${item.color_code}`}}
                >
                <OverlayTrigger
                    placement='right'
                    overlay={<Tooltip id={`tooltip-${item.id}`}>{item.name}</Tooltip>} // Show segment name as tooltip
                  >
                  <button
                   className={`nav-link ${masterSegName === item.name ? 'active' : ''}`}
                    id={`${item.id}-tab`}
                    data-bs-toggle='tab'
                    data-bs-target={`#${item.id}`}
                    type='button'
                    role='tab'
                    aria-controls={`${item.id}`}
                    aria-selected={masterSegName == item.name ? 'true' : 'false'}
                    onClick={() => handleEachSegment(item)}
                    onMouseEnter={()=>handleHoverGroup(item.name??"")}
                    onMouseLeave={()=>handleRemoveHover(item.name??"")}
                   // style={{backgroundColor:`${item.color_code}`}}
                   style={{
                    border: `1px solid ${item.color_code}`, // Dynamic border
                    borderRadius: '6px',                    // Rounded corners
                  }}
                  >
                    <span className='icon'>
                    {/* <ReactSVG src={`${item.icon}`} /> */}
                    <ReactSVG
                            src={`${item.icon}`}
                              beforeInjection={(svg) => {
                                svg.setAttribute('style', 'width: 100%; height: 100%;');
                                // Add a border with dynamic color
                                svg.setAttribute('style', `width: 100%; height: 100%; border-radius: 4px;`);
                              }}
                              onError={(error) => {
                                console.error('Error loading SVG:', error);
                              }}
                            />
               
                    </span>
                  </button>
                  </OverlayTrigger>
                </li>
                
                </>
                
              )
            })}
              
              <AddSegment/>
              
           
        </ul>
        
        <div className='tab-content tab-sub-container' id='myTabContent'>
          {eachSegment?.name == masterSegName && (
            <EachSeg
              masterSegName={masterSegName}  //groupname
              eachSegment={eachSegment || {}}  
            />
          )}
          
        </div>
      </div>
    </>
  )
}

export default Segments
