// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0%, 57.14% {
    animation-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);
    transform: translate(0);
  }
  28.57% {
    animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);
    transform: translateY(-6px);
  }
  100% {
    transform: translate(0);
  }
}
.spinner.qM83 {
  animation: spinner 1.05s infinite;
}
.spinner.oXPr {
  animation: spinner 1.05s infinite;
  animation-delay: 0.1s;
}
.spinner.ZTLf {
  animation: spinner 1.05s infinite;
  animation-delay: 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/module/createproject/Spinner.scss"],"names":[],"mappings":"AAGA;EACI;IACI,4DAAA;IACA,uBAAA;EAFN;EAIE;IACI,4DAAA;IACA,2BAAA;EAFN;EAIE;IACI,uBAAA;EAFN;AACF;AAOI;EACI,iCAAA;AALR;AAQI;EACI,iCAAA;EACA,qBAAA;AANR;AASI;EACI,iCAAA;EACA,qBAAA;AAPR","sourcesContent":["// SCSS Spinner Styles\n\n// Keyframes for spinner animation\n@keyframes spinner {\n    0%, 57.14% {\n        animation-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);\n        transform: translate(0);\n    }\n    28.57% {\n        animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);\n        transform: translateY(-6px);\n    }\n    100% {\n        transform: translate(0);\n    }\n}\n\n// Spinner Circles\n.spinner {\n    &.qM83 {\n        animation: spinner 1.05s infinite;\n    }\n\n    &.oXPr {\n        animation: spinner 1.05s infinite;\n        animation-delay: 0.1s; // Delay to stagger animation\n    }\n\n    &.ZTLf {\n        animation: spinner 1.05s infinite;\n        animation-delay: 0.2s; // Delay to stagger animation\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
