import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectCommentModel } from "../../Model/comments/CommentsModel";


interface ProjectCommentStates {
  projectComments:ProjectCommentModel[]
    isCommentTab:boolean
    iscomment:boolean
    canvasGroup:{
      groupName:string,
      subGroupName:string,
      segShortName:string,
      segName:string,
      isMainComment:boolean,
      isUserIcon:boolean,
      isEditComment:boolean
      pointer:{
        x:number,
        y :number
      }
    }
   
    
}

const initialState: ProjectCommentStates = {
  projectComments:[],
    isCommentTab:false,
    iscomment:false,
    canvasGroup:{
      groupName:"",
      subGroupName:"",
      segShortName:"",
      segName:"",
      isMainComment:false,
      isUserIcon:false,
      isEditComment:false,
      pointer:{
        x:0,
        y:0
      }
    }

};

const ProjectCommentSlice = createSlice({
    name: "projectComment",
    initialState,
    reducers: {
      addProjectComment:(state,action)=>{
        const {addMessage}=action.payload
        
          const comment= state.projectComments
       
          if(comment && comment.length==0){
            state.projectComments= action.payload.addMessage
          }else{
            state.projectComments.push(addMessage)
          }
         
        
      },
      
      openCommentTab:(state)=>{
        state.isCommentTab= true
        state.iscomment=true
      },
      closeCommentTab:(state)=>{
        state.isCommentTab= false
      
      }, 
      addComment:(state,action)=>{
        const {groupName,subGroupName,segShortName,segName,isMainComment,isUserIcon,isEditComment, x ,y}= action.payload
        state.canvasGroup.groupName= groupName
        state.canvasGroup.subGroupName= subGroupName
        state.canvasGroup.segShortName= segShortName
        state.canvasGroup.segName= segName
        state.canvasGroup.isMainComment= isMainComment
        state.canvasGroup.isUserIcon= isUserIcon
        state.canvasGroup.isEditComment= isEditComment
        state.canvasGroup.pointer.x=x
        state.canvasGroup.pointer.y=y


      }, 
      closeMainCanvas:(state)=>{
        state.canvasGroup.isMainComment= false
        state.canvasGroup.isUserIcon= true

      }, resetCanvasComment:(state)=>{
        state.canvasGroup.groupName= ""
        state.canvasGroup.subGroupName= ""
        state.canvasGroup.segShortName= ""
        state.canvasGroup.segName= ""
        state.canvasGroup.isMainComment= false
        state.canvasGroup.isUserIcon= false
        state.canvasGroup.isEditComment= false
        state.canvasGroup.pointer.x=0
        state.canvasGroup.pointer.y=0
        state.isCommentTab=false
        state.iscomment= false
        state.projectComments=[]
      }, 

    }
}
)

export const {openCommentTab,closeCommentTab,
  addComment,closeMainCanvas,resetCanvasComment,
  addProjectComment
}=ProjectCommentSlice.actions;
export const getComment= (state:{projectComment:ProjectCommentStates})=>state.projectComment;
export const getCavasGroupComments= (state:{projectComment:ProjectCommentStates})=>state.projectComment.canvasGroup;

export default ProjectCommentSlice.reducer