import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { getAllSegment } from '../../../../slice/segment/SegmentSlice'

const AddSegmentModal = () => {


    const getMasterArrays= useSelector(getMasterArray)
    const getAllSegments= useSelector(getAllSegment)
    const [addSegment, setAddSegment]= useState<string[]>([])

    useEffect(()=>{
        if(getMasterArrays && 
            getMasterArrays.allSeg &&
            getMasterArrays.allSeg.length>0 &&
            getAllSegments && getAllSegments.length>0
        ){
            let segmentName:string[]=[]
            getMasterArrays.allSeg.map(item=>{
                getAllSegments.map(seg=>{
                    if( item.name && seg.name
                        &&item.name!= seg.name){
                        segmentName.push(seg.name)
                    }
                })
            })
            if(segmentName.length>0){
                console.log("segmentName",segmentName)
                setAddSegment(segmentName)
            }else{
                alert("No addition is present")
            }

        }
    },[getMasterArrays,getAllSegments])

  return (
    <>
    </>
  )
}

export default AddSegmentModal