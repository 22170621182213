// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.magnifier-lens {
    position: absolute;
    border: 1px solid #000;
    border-radius: 50%;
    cursor: none;
    z-index: 10;
    width: 150px;
    height: 150px;
    overflow: hidden;
    pointer-events: none;
    display: block;
   margin-top: 50px;
  }
  
  .magnifier-content {
    position: absolute;
    
    transform: scale(2); /* Magnification factor */
    transform-origin: top left;
  }

`, "",{"version":3,"sources":["webpack://./src/module/canavas/reannotationCanvas/reAnnotationRight/Mangnifier.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;GACf,gBAAgB;EACjB;;EAEA;IACE,kBAAkB;;IAElB,mBAAmB,EAAE,yBAAyB;IAC9C,0BAA0B;EAC5B","sourcesContent":[".magnifier-lens {\n    position: absolute;\n    border: 1px solid #000;\n    border-radius: 50%;\n    cursor: none;\n    z-index: 10;\n    width: 150px;\n    height: 150px;\n    overflow: hidden;\n    pointer-events: none;\n    display: block;\n   margin-top: 50px;\n  }\n  \n  .magnifier-content {\n    position: absolute;\n    \n    transform: scale(2); /* Magnification factor */\n    transform-origin: top left;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
