// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button{    border: 1px solid #cbd5e0;
    background: #f6f6f9;
    border-radius: 5px; padding-top: 3px; padding-bottom: 3px; padding-left: 10px;}
    .radio-button .css-1bb1pgq{background-color: #fff; padding: 3px 38px; 
    box-shadow: none!important;
    }

    .checkbox-status .css-1bb1pgq{background-color: #fff; padding: 3px 33px; 
        box-shadow: none!important;
        }
    .css-1bb1pgq[aria-checked=true], .css-1bb1pgq[data-checked]{background: #6d455a!important; border-color: #6d455a!important;}`, "",{"version":3,"sources":["webpack://./src/module/brandPage/EditProjectFrom.css"],"names":[],"mappings":"AAAA,kBAAkB,yBAAyB;IACvC,mBAAmB;IACnB,kBAAkB,EAAE,gBAAgB,EAAE,mBAAmB,EAAE,kBAAkB,CAAC;IAC9E,2BAA2B,sBAAsB,EAAE,iBAAiB;IACpE,0BAA0B;IAC1B;;IAEA,8BAA8B,sBAAsB,EAAE,iBAAiB;QACnE,0BAA0B;QAC1B;IACJ,4DAA4D,6BAA6B,EAAE,+BAA+B,CAAC","sourcesContent":[".radio-button{    border: 1px solid #cbd5e0;\n    background: #f6f6f9;\n    border-radius: 5px; padding-top: 3px; padding-bottom: 3px; padding-left: 10px;}\n    .radio-button .css-1bb1pgq{background-color: #fff; padding: 3px 38px; \n    box-shadow: none!important;\n    }\n\n    .checkbox-status .css-1bb1pgq{background-color: #fff; padding: 3px 33px; \n        box-shadow: none!important;\n        }\n    .css-1bb1pgq[aria-checked=true], .css-1bb1pgq[data-checked]{background: #6d455a!important; border-color: #6d455a!important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
