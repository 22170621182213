import React, { useState } from "react";
import "./ZoomCanvas.scss";
import { useTheme } from "../../../../context/ThemeContext";

const ZoomCanvas = () => {
  const eyeIcon = document.getElementById("eyeIcon") as HTMLElement;
  const { theme, toggleTheme } = useTheme();
  if (eyeIcon) {
    // Toggle between 'bi-eye' and 'bi-eye-slash'
    if (eyeIcon.classList.contains("bi-eye")) {
      eyeIcon.classList.remove("bi-eye");
      eyeIcon.classList.add("bi-eye-slash"); // Switch to eye-slash icon
    } else {
      eyeIcon.classList.remove("bi-eye-slash");
      eyeIcon.classList.add("bi-eye"); // Switch back to eye icon
    }
  }

  const [isCardVisible, setIsCardVisible] = useState(false);

  // Function to toggle the visibility of the display options
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  return (
    <div>
      <div className="position-absolute d-flex canvas-mode-section">
        <div className=" bg-white   rounded-pill zoomContainer">
          <span className="me-3">
            <i className="bi bi-dash"></i>
          </span>
          <h6>20%</h6>
          <span className="mx-3">
            <i className="bi bi-plus"></i>
          </span>
          <h6 className="zoom-reset">Reset</h6>
        </div>

        <div
          className="bg-white rounded-circle brightness"
          onClick={toggleCardVisibility}>
          <i className="bi bi-brightness-high"></i>
        </div>
      </div>

      {isCardVisible && (
        <div className="card display-mode-sec">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="card-title m-0">Display Options</h5>
              <span className="refresh-icon">
                <i className="bi bi-arrow-counterclockwise"></i>
              </span>
            </div>

            <div className="flex flex-col gap-1 range my-2">
              <label className="form-label">Contrast</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="flex flex-col gap-1 range mb-2">
              <label className="form-label">Brightness</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <label className="form-label">Shade</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <span>Always Show Labels</span>
              <label className="showLabels relative border rounded-pill">
                <input type="checkbox" className="peer sr-only" />
                <div className="optionContainer"></div>
                <div className="option off text-primary">
                  <i className="bi bi-eye"></i>
                </div>
                <div className="option on text-primary">
                  <i className="bi bi-eye-slash"></i>
                </div>
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-between ">
              <span>Display mode</span>
              <label className="showLabels relative border rounded-pill">
                <input type="checkbox" className="peer sr-only"  
                onChange={toggleTheme}
                />
                <div className="optionContainer"></div>
                <div className="option off text-primary">
                  <i className="bi bi-brightness-high"></i>
                </div>
                <div className="option on text-primary">
                  <i className="bi bi-moon"></i>
                </div>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomCanvas;
