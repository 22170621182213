import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addMaterialStyle, getMaterialStyle } from '../../../slice/materialSlice/MaterialStyleSlice'
import { GetAllMaterialsStyle } from '../../../api/material/style/styleApi'

const GetAllMaetrial_brand_style = () => {

  const dispatch= useDispatch()
  const getMaterialStyles= useSelector(getMaterialStyle)
   useEffect(()=>{
     if(getMaterialStyles && getMaterialStyles.length===0){
      getAllMaterials_Brand_style()
     }
      
   },[getMaterialStyles])

    const getAllMaterials_Brand_style= async()=>{
        try{
     
             const response= await GetAllMaterialsStyle()
                if(response && response.status===200){
                  dispatch(addMaterialStyle(response.data))
                }
        }catch(err){
           console.log("err in getting all material")
        }

    }
  return (
    <div>

    </div>
  )
}

export default GetAllMaetrial_brand_style