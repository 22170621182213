// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loding-icons {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.loding-icons .loading-img {
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/module/ImageView/RightSection/rightshimmer/RightShimmer.scss"],"names":[],"mappings":"AAwCA;EACI,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;AAvCJ;AAyCE;EAAa,mBAAA;AAtCf","sourcesContent":["// .shimmer-shines {\n//     background: #f6f7f8;\n//     background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);\n//     background-repeat: no-repeat;\n//     background-size: 100% 100%; \n//     display: inline-block;\n//     position: relative; \n//     -webkit-animation-duration: 1s;\n//     -webkit-animation-fill-mode: forwards; \n//     -webkit-animation-iteration-count: infinite;\n//     -webkit-animation-name: placeholderShimmer;\n//     -webkit-animation-timing-function: linear;\n//   }\n  \n//   .right-sec-shimmer {\n//     display: inline-flex;\n//     flex-direction: column; \n\n\n//     vertical-align: top;\n//     width: 100%; /* Ensure the container takes fullwidth */ \n//     height:100%; \n//   }\n  \n//   .shimer-images {\n//     height: 100vh;\n//     width: 100%; /* Full width of the container */\n//     border-left: 1px solid #ddd;\n//   }\n  \n//   @-webkit-keyframes placeholderShimmer {\n//     0% {\n//       background-position: -468px 0;\n//     }\n    \n//     100% {\n//       background-position: 468px 0; \n//     }\n//   }\n\n.loding-icons{\n    position:absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n\n  .loading-img{vertical-align: top;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
