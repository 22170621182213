// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumb-link{
    color: #2d3748;
    font-weight: 400;

}

.breadcrum-section{justify-content: center;
    display: flex;
    align-items: center;}`, "",{"version":3,"sources":["webpack://./src/module/brandPage/swatch/Swatch.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;;AAEpB;;AAEA,mBAAmB,uBAAuB;IACtC,aAAa;IACb,mBAAmB,CAAC","sourcesContent":[".Breadcrumb-link{\n    color: #2d3748;\n    font-weight: 400;\n\n}\n\n.breadcrum-section{justify-content: center;\n    display: flex;\n    align-items: center;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
