import React from 'react'
import "./RightShimmer.scss"
const RightShimmer = () => {
    return (
        <>
            {/* <div className="right-sec-shimmer">
    <span className="shimer-images shimmer-shines"></span>
    </div> */}
          <div className="d-flex justify-content-center align-items-center  vh-100 loding-icons">
                <div className='loading-icon text-center d-flex'>
                    <h4>Loading
                       
                    </h4>
                    <svg className='loading-img' width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <circle className="spinner qM83" cx="4" cy="12" r="2" />
                            <circle className="spinner oXPr" cx="12" cy="12" r="2" />
                            <circle className="spinner ZTLf" cx="20" cy="12" r="2" />
                        </svg>
                </div>
            </div>
        </>
    )
}

export default RightShimmer
