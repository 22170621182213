
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAddSegmentStatus } from '../../../../slice/canvas/updatevalue/UpdateValueSlice'
import AddSegmentModal from './AddSegmentModal'

const AddSegmentHome = () => {

    const getAddSegmentStatuss= useSelector(getAddSegmentStatus)
    const[isModal, setIsModal]= useState<boolean>(false)
    useEffect(()=>{
        if(getAddSegmentStatuss){
            setIsModal(true)
        }else{
            setIsModal(false) 
        }
    },[getAddSegmentStatuss])
  return (
    <>

    {isModal &&
    <AddSegmentModal/>}
        
    </>
  )
}

export default AddSegmentHome