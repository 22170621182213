import React, { useEffect, useState } from 'react'
import CreateProject from './CreateProject'
import { CreateProjectModel } from '../../Model/project/ProjectModel'
import CreateProjectAPi from './CreateProjectAPi'
import CallModelApi from './CallModelAPI/CallModelApi'
import { getLoading, startLoading, stopLoading } from '../../slice/loading/LoadingSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../loading/Loading'
import { useNavigate } from 'react-router-dom'
import UpdateNewProjectApi from './UpdateNewProjectApi'
import UploadImageS3Api from './uploadImage/UploadImageS3Api'
import { getUserLogin } from '../../slice/user/UserSlice'
import Project from '../leftSection/Project'
import { userModel } from '../../Model/user/UserModel'
import SamData from './samData/SamData'
import JobDB from './samData/JobDB'
import { getUserJobData } from '../../slice/userJobSlice/UserJobSlice'
import { getUserProjectImageUrl } from '../../slice/user/UserProject'
import ModelApi from './CallModelAPI/ModelApi'
import UploadImageHome, { responseImage } from './uploadImage/UploadImageHome'
import { addUploadMessage, resetUploadMessage } from '../../slice/modelData/ModelDataSlice'
import Header from '../header/Header'
import MessageToast from '../messageToast/MesageToast'
import IndexPage from '../../page/samModel/IndexPage'
import UploadEmdedingHome from './uploadImbedding/UploadEmdedingHome'

const CreateProjectHome = () => {
  const[isModelApi, setISModelApi]= useState<boolean>(false)
  const[isCreatePoject, setIsCreateProject]= useState<boolean>(true)
  const[projectdata, setProjectData]= useState<CreateProjectModel|null|undefined>()
 const [isCreateProjectAPi, setISCreateProjectApi] = useState<boolean>(false)
 const [isUpdateProjectAPi, setIsUpdateProjectApi] = useState<boolean>(false)
 const [isUploadImageApi, setIsUploadImageApi] = useState<boolean>(false)
 const [isSamData, setIsSamData] = useState<boolean>(false)
 const [isJobApi, setIsJobApi] = useState<boolean>(false)
 const [isSaveEmbedding, setIsSaveEmbedding]= useState<boolean>(false)
    const dispatch= useDispatch();
    const navigate= useNavigate()
   const getUserLogins= useSelector(getUserLogin)
// set as static data for the guest 
useEffect(()=>{
  if(getUserLogins &&
    getUserLogins.length==0
  ){
    const data:userModel={
      id:111,
      name:"Guest",
      email:"guest@gmail.com",
      role:"guest",
      projects:[]
    }
  }
},[getUserLogins])

  const handleCreateProject=( data:CreateProjectModel)=>{
 
    setProjectData(data)
    setISCreateProjectApi(true)
       
     dispatch(startLoading())
 
  }

 const  handleCloseCreateProject=()=>{
  setISCreateProjectApi(false)
 }

  
  const handleResetProjectApi=(data:string)=>{
    
    if(data==="success"){
     setISCreateProjectApi(false)
      setIsUploadImageApi(true)
      dispatch(addUploadMessage("Uploading Image "))
    //setISModelApi(true)
  } else {
    setISCreateProjectApi(false)
    dispatch(stopLoading())
    alert("Error in creating project")
  }
  }

  // call back upload image
  const [originalImage, setOriginalImage]= useState<string|null>()
  const [compressImage, setcompressImage]= useState<string|null>()
  const handleResetUploadImage=(mess:string)=>{
      
     if(mess==="fail") {
     setIsUploadImageApi(false)
     dispatch(stopLoading())
     alert("Error in upload image in S3 bucket")
    } 
    //else{
    //   setIsUploadImageApi(false)
    //   setISModelApi(true)
    //   setUrl(mess)
    // }
}
 

const handleUploads=(data:responseImage)=>{
  if(data){
    // dispatch(addUploadMessage("Preparing Image "))
    dispatch(addUploadMessage("Preparing Embeding Image "))
    setIsUploadImageApi(false)
       //setISModelApi(true)
       setIsSaveEmbedding(true)
     setOriginalImage(data.original)
     setcompressImage(data.compress)
     
  }

}
const handleResetEmbeded=()=>{
  dispatch(addUploadMessage("Preparing Image "))
  setIsSaveEmbedding(false)
  setISModelApi(true)
}
const handleCancelImageUpload=()=>{
  
}

const handleResetUrl=(data:string)=>{
  if(data==="success"){
        setISModelApi(false)
        setOriginalImage(null)
        setcompressImage(null)
       // setIsUpdateProjectApi(true)
         setIsSamData(true)
      //  navigate("/imageview")
   
  } else if (data==="fail"){
    setISModelApi(false)
    navigate("/")
  }

}
 

   const handleResetSamData=()=>{
    dispatch(addUploadMessage("Saving project details "))
    setIsSamData(false)
    setIsJobApi(true)
   }

   const handleResetJob=()=>{
    setIsJobApi(false)
    setIsUpdateProjectApi(true)
    
    }
    const[jobId, setJobId]= useState<number|null|undefined>()
    const getUserJobDatas= useSelector(getUserJobData)
// update joBId
useEffect(()=>{
  if(getUserJobDatas && getUserJobDatas.id){
    setJobId(getUserJobDatas.id)
  }
},[getUserJobDatas])
  const handleResetUpdateApi=(mess:string)=>{ 
    setIsUpdateProjectApi(false)
    
    if(mess==="success"){
          dispatch(resetUploadMessage())
         navigate(`/customer/project`)
         dispatch(stopLoading())
    }else if(mess==="fail"){
      
      dispatch(stopLoading())

    }

  }

 
  // loaging
  const getLoadings= useSelector(getLoading)
  const [isLoading, setisLoading]= useState<boolean>(false)
  useEffect(()=>{
  
    if(getLoadings){
      setisLoading(true)
    }
  },[getLoadings])
  return (
    <>
     
    <div className='h-100'>
    <Header/>


     {isCreatePoject && <CreateProject
      callAPI={handleCreateProject}
      resetCreateProject={handleCloseCreateProject}
      />
}

    
      {/* create project APi */}
  {isCreateProjectAPi && 
     projectdata && 
     <CreateProjectAPi
       createProjectData={projectdata}
       resetProjectApi= {handleResetProjectApi}
       />}


 {/*  upload Image s3 bucket */}

     {isUploadImageApi &&

        <UploadImageHome
        isUploadImageApi={isUploadImageApi}
        resetUploadImage={handleResetUploadImage}
        resetUploadHomeImage={handleUploads}
       
        />
        }

        {/* upload the embedding image */}
       {isSaveEmbedding && 
       <UploadEmdedingHome
       resetEmbeded={handleResetEmbeded}
       />}
     {/* {isUploadImageApi &&
        <UploadImageS3Api
        resetUploadImage={handleResetUploadImage}
       
        />
        } */}



            {/* call Model APi */}
            {isModelApi && 
             originalImage &&
             compressImage &&
                <ModelApi
                url={originalImage}
                
                isBase64={!isModelApi}
            resetCallApi={handleResetUrl}
                  />}


             {/*  segregate data */}
              {isSamData &&
              <SamData
              resetSamData={handleResetSamData}
              />}


              {/* add job into db */}
              {isJobApi &&
              <JobDB
              resetJob={handleResetJob}
             
              />}

      {/*  update projectAPi */}

      {isUpdateProjectAPi  &&
        <UpdateNewProjectApi 
          resetUpdateProject={handleResetUpdateApi}
          />}



         




{/*  update jobs id into project */}

{/* { isUpdateJob &&
 projectId && jobId &&
<UpdateJobIntoProject
projectId={projectId}
jobId={jobId}
resetUpdateJob={handleResetUpdateJob}
/>} */}

<MessageToast/>

{isLoading && <Loading/>}
    
    </div>
    </>
 
  )
}

export default CreateProjectHome