import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./UserComments.scss"
import { useSelector } from 'react-redux';
import { ProjectCommentModel } from '../../../../Model/comments/CommentsModel';
import { getComment } from '../../../../slice/projectCommet/ProjectCommentSlice';
const UserComments = () => {

    const [hoveredComment, setHoveredComment] = useState<number | null>(null);
    const [userComment, setUserComment] = useState<ProjectCommentModel[]>([]);
    const getComments=useSelector(getComment)

    useEffect(()=>{
      if(getComments && getComments.projectComments &&
        getComments.projectComments.length>0 
      ){
        
        setUserComment( getComments.projectComments)
      }
       else{
        setUserComment([])
       }
    },[getComments])
    // State to manage hover
    const [hovered, setHovered] = useState(false);
 
    // Tooltip content
    const renderTooltip = (props: any)  => (
      <Tooltip id="comment-tooltip" {...props} className="custom-tooltip">
       vijendrachoudhary@gmail.com
      </Tooltip>
    );
 
    const comments = [
     { id: 1, name: 'Vijendra Jat', text: 'aaabbb', date: '2024/10/14 02:25' },
     { id: 2, name: 'Vijendra Jat', text: 'aaabbb', date: '2024/10/14 02:25' },
     { id: 3, name: 'Vijendra Jat', text: 'aaabbb', date: '2024/10/14 02:25' },
     { id: 4, name: 'Vijendra Jat', text: 'aaabbb', date: '2024/10/14 02:25' },
     { id: 5, name: 'Vijendra Jat', text: 'aaabbb', date: '2024/10/14 02:25' },

   ];
   const [filter, setFilter] = useState<'all' | 'unresolved'>('all'); // Filter state
   const [isResolved, setIsResolved] = useState(false); // For checkmark filtering
//    const filteredComments = filter === 'all' ? comments : comments.filter((comment) => !comment.resolved);

   const handleAddEdit=()=>{

   }

  return (

    <div className='comment-tab'>


    <div className='border-bottom d-flex align-items-center justify-content-between py-1 px-3 comment-section'>
     <h6 className='text-primary m-0 ps-2'>Comments</h6>
    
     {/* <DropdownButton
          id="filter-dropdown"
          title={<i className="bi bi-filter"></i>}
          className="btn-transprant filter-comment-icon"
          onSelect={(e) => setFilter(e as 'all' | 'unresolved')}
        >
          <Dropdown.Item eventKey="all">All Comments</Dropdown.Item>
          <Dropdown.Item eventKey="unresolved">Unresolved Comments</Dropdown.Item>
        </DropdownButton> */}

    </div>
 
 <div className='comments-user-content'>

 {userComment && userComment.length === 0 ? (
  <h6>No comments</h6>
) : (
  userComment.map(item => {
    const replies = item.replies;
    return (
      replies && replies.length > 0 &&
      replies.map((comment, index) => {
        const createdAtDate = comment.createdAt ? new Date(comment.createdAt) : null;
        return (
          <div
            key={comment.message}
            className="user-comment-sec d-flex"
            onMouseLeave={() => setHoveredComment(null)}
            onClick={handleAddEdit}
          >
            {/* Tooltip Wrapper */}
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div className="w-100 p-2 position-relative">
                <h6 className="position-absolute check-comment">
                  <i className="bi bi-check-circle me-2"></i>
                </h6>

                <div className="avatarCircle avatar-user rounded-circle mb-3 ms-1">
                  <p className="m-0">{comment.name?.charAt(0)}</p>
                </div>

                <div className="flex-column align-items-start ps-2">
                  <h6 className="text-truncate m-0 d-flex align-items-center">
                    {comment.name}
                    <span className="text-muted small ms-2">
                      {createdAtDate
                        ? createdAtDate.toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }) + " at " + createdAtDate.toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })
                        : "Unknown Date"}
                    </span>
                  </h6>
                  <div className="text-wrap">
                    <p className="small">{comment.message}</p>
                  </div>
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      })
    );
  })
)}

  
       </div>
</div> 
  )
}

export default UserComments

// {
//   comments.map((comment) => (
//   <div
//          key={comment.id}
//          className="user-comment-sec d-flex"
//          onMouseEnter={() => setHoveredComment(comment.id)} 
//          onMouseLeave={() => setHoveredComment(null)} 
//        >
        
//          <OverlayTrigger
      
//            delay={{ show: 250, hide: 400 }}
//            overlay={renderTooltip}
//          >
//            <div className="w-100 p-2 position-relative">
           
//              {hoveredComment === comment.id && (
//                <h6 className="position-absolute check-comment">
//                  <i className="bi bi-check-circle me-2"></i>
//                </h6>
//              )}

//              <div className="avatarCircle avatar-user rounded-circle mb-3 ms-1">
//                <p className="m-0">V</p>
//              </div>

//              <div className="flex-column align-items-start ps-2">
//                <h6 className="text-truncate m-0 d-flex align-items-center">
//                  {comment.name}
//                  <span className="text-muted small ms-2">{comment.date}</span>
//                </h6>
//                <div className="text-wrap">
//                  <p className="small">{comment.text}</p>
//                </div>
//              </div>
//            </div>
//          </OverlayTrigger>
//        </div>
//      ))}