import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getGenAiAttributes, getGenAiImage, getPromptValues, startGenAiImage } from '../../../slice/genAi/GenAiAttributesSlice';
import GenAiFeatures from './tabs/GenAiFeatures';
import GenAiSegments from './tabs/GenAiSegments';
import GenAiGallery from './tabs/GenAiGallery';
import GetPrompt from './getPrompt/GetPrompt';
import PromptToAiImage from './promptToAiImage/PromptToAIImage';
import GetGenAiImage from '../genAi/GetGenAiImage';
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice';
import "./AiExteriors.scss"

export interface GenAiAttribute {
  type: 'feature' | 'segment'; // Define types for attributes
  // Add other properties if needed
}

export interface GenAiAttributesModel {
  [key: string]: GenAiAttribute; // Allow indexing with string keys
}

const s3BucketPath = process.env.REACT_APP_S3Bucket;

const AiExteriors = () => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [promptData, setPromptData] = useState<string | null>(null);
  const [allGenAttrFeature, setAllGenAttrFeature] = useState<GenAiAttributesModel | null>(null);
  const [allGenAttrSegments, setAllGenAttrSegments] = useState<GenAiAttributesModel | null>(null);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState<any>({});
  const [imagePath, setImagePath] = useState<string | null>(null);
  const [isGen, setIsGen] = useState<boolean>(false);
  const [isGenImage, setIsGenImage] = useState<boolean>(false);
  const [tabSelector, setTabSelector] = useState<string | null>(null);

  const dispatch = useDispatch();
  const getGenAiAttributess = useSelector(getGenAiAttributes);
  const getGenAiImages = useSelector(getGenAiImage);
  const getPromptValuess = useSelector(getPromptValues);
  const getMasterArrays = useSelector(getMasterArray);

  useEffect(() => {
    if (getMasterArrays?.image && getMasterArrays?.project_id) {
      const image = `${s3BucketPath}/projects/${getMasterArrays.project_id}/${getMasterArrays.image}`;
      setImagePath(image);
    }
  }, [getMasterArrays]);

  useEffect(() => {
    setIsGenImage(!!getGenAiImages);
  }, [getGenAiImages]);

  useEffect(() => {
    if (getGenAiAttributess && getGenAiAttributess.length > 0) {
      const allAtt = getGenAiAttributess[0] as GenAiAttributesModel; // Explicitly cast to GenAiAttributesModel
      // Set features and segments from attributes
      const featureAttributes: GenAiAttributesModel = {};
      const segmentAttributes: GenAiAttributesModel = {};

      Object.keys(allAtt).forEach((key) => {
        const attribute = allAtt[key]; // Access attribute directly
        if (attribute.type === 'feature') {
          featureAttributes[key] = attribute; // Correctly typed attribute
        } else if (attribute.type === 'segment') {
          segmentAttributes[key] = attribute; // Correctly typed attribute
        }
      });

      setAllGenAttrFeature(featureAttributes);
      setAllGenAttrSegments(segmentAttributes);
    }
  }, [getGenAiAttributess]);

  const handleGallery = () => setTabSelector('gallery');
  const handleProfile = () => setTabSelector('profile');
  const handleHome = () => setTabSelector('home');

  const handleGenAiImage = () => {
    setIsGen(true);
    dispatch(startGenAiImage());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptData(event.target.value);
  };

  const handleResetGenAi = () => {
    setIsGen(false);
  };

  return (
    <>
      <div className='ai-exteriors-sec'>
        <div className='aiexter-custo-sec py-2'>
          <Tabs activeKey={tabSelector || 'home'} onSelect={setTabSelector}  fill>
            <Tab className='home-tab' eventKey='home' title={<span className='tab'>Features</span>} onClick={handleHome}>
              {allGenAttrFeature && <GenAiFeatures allGenAttrFeature={allGenAttrFeature} />}
            </Tab>
            <Tab className='profile-tab' eventKey='profile' title={<span className='tab'>Segments</span>} onClick={handleProfile}>
              {allGenAttrSegments && <GenAiSegments allGenAttrSegments={allGenAttrSegments} />}
            </Tab>
            <Tab  className='gallery-tab' eventKey='gallery' title={<span className='tab'>Gallery</span>} onClick={handleGallery}>
              <GenAiGallery />
            </Tab>
          </Tabs>
        </div>

        {tabSelector !== 'gallery' && (
          <div className='gen-layout px-4'>
            <div className='pt-3'>
              <h6 className='fw-medium'>
                Custom AI Instructions
                <span data-bs-toggle='popover' data-bs-trigger='hover focus' data-bs-content='Information about house angles.'>
                  <i className='bi bi-question-circle ps-1'></i>
                </span>
              </h6>
              <textarea
                className='form-control from-textarea'
                id='exampleFormControlTextarea1'
                rows={3}
                value={promptData || ''}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className='d-flex gap-1 mt-2'>
              <span className='switch-checkbox'>Private Gallery</span>
              <div className='form-check form-switch ms-2'>
                <input className='form-check-input' type='checkbox' role='switch' id='flexSwitchCheckDefault' />
              </div>
              <span className='switch-checkbox'>Public Gallery</span>
            </div>
            <button className='btn btn-primary rounded-pill create-btn mt-2 w-100' onClick={handleGenAiImage}>
              Generate with Ai
            </button>
          </div>
        )}

      </div>

      {selectedDropdownValues && Object.keys(selectedDropdownValues).length > 0 && (
        <GetPrompt selectedDropdownValues={selectedDropdownValues} resetPrompt={setPromptData} />
      )}

      {imagePath && promptData && isGen && (
        <PromptToAiImage promptData={promptData} imagePath={imagePath} resetImagePath={handleResetGenAi} />
      )}

      {isGenImage && <GetGenAiImage />}
    </>
  );
};

export default AiExteriors;
