// This source code is licensed under the license found in the
// LICENSE file in the root directory of this source tree.
import { Tensor} from "onnxruntime-web";
import { modeDataProps,
   modelInputProps,
    queryModelReturnTensorsProps,
     setParmsandQueryModelProps } from "../helper/Interfaces";

import { useSelector } from "react-redux";

const API_ENDPOINT = process.env.REACT_APP_BACKEND_URL;
const serverApi= process.env.REACT_APP_SERVER_URL;

const setParmsandQueryModel = ({
  width,
  height,
  uploadScale,
  imgData,
  handleSegModelResults,
  imgName,
  projectId

}: setParmsandQueryModelProps) => {
  if (width === undefined || height === undefined || uploadScale === undefined) {
    console.error("Width, height, or uploadScale is undefined");
    return; // Exit the function early if any are undefined
  }
  const canvas = document.createElement("canvas");
  const scaledWidth = width * (uploadScale ?? 1);  // Fallback to 1 if undefined
const scaledHeight = height * (uploadScale ?? 1);
  canvas.width = Math.round(scaledWidth);
  canvas.height = Math.round(scaledHeight);
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  if (!imgData) {
    console.error("imgData is undefined");
    return; // Exit if imgData is undefined
  }
  ctx.drawImage(imgData, 0, 0, canvas.width, canvas.height);
  canvas.toBlob(
    (blob) => {
      blob &&
        queryModelReturnTensors({
          blob,
          handleSegModelResults,
          imgName,
          projectId
        });
    },
    "image/jpeg",
    1.0,
  );
};

const queryModelReturnTensors = async ({
  blob,
  handleSegModelResults,
  imgName,
  projectId
}: queryModelReturnTensorsProps) => {
  if (!API_ENDPOINT ||blob == undefined) return;
  const req_data = new FormData();
  req_data.append("file", blob, imgName);

  const segRequest = fetch(`${API_ENDPOINT}/image-embedding`, {
    method: "POST",
    body: req_data,
  });
   console.log("segRequest",segRequest)
  segRequest.then(async (segResponse) => {
    const segJSON = await segResponse.json();
       // Save data to S3 bucket
    //    if(segJSON && segJSON.image_embedding){
    //     //const saveToS3Response = await saveEmbeddingToS3(segJSON.image_embedding, imgName ?? "", projectId ?? 0);
    //  //const saveToS3Response=  await EmbeddingValueToS3(segJSON.image_embedding, imgName??"",projectId??0);
    // //console.log("saveToS3Response",saveToS3Response)
    
    
    //    }

    console.log("image_embedding",segJSON.image_embedding)
    console.log("Type of image_embedding:", typeof segJSON.image_embedding);

    // const embedArr = segJSON.map((arrStr: string) => {
      const binaryString = window.atob(segJSON.image_embedding);
      //console.log('binaryString',binaryString)
      const uint8arr = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        uint8arr[i] = binaryString.charCodeAt(i);
      }
      const float32Arr = new Float32Array(uint8arr.buffer);
    //  console.log("float32Arr",float32Arr)
      // return float32Arr;
   // });
    const lowResTensor = new Tensor("float32", float32Arr, [1, 256, 64, 64]);
    console.log("lowResTensor",lowResTensor)
    if (handleSegModelResults) {
      handleSegModelResults({
        tensor: lowResTensor,
      });
    } else {
      console.error("handleSegModelResults is undefined");
    }
    // handleSegModelResults({
    //   tensor: lowResTensor,
    // });
  });
};
// New function to save embedding to S3
const saveEmbeddingToS3 = async (embedding: string, imgName: string, projectId: number) => {
  const embeddingBlob = new Blob([embedding], { type: 'text/plain' });
  const fileName = `${projectId}_${imgName}_embedding.txt`;

  const formData = new FormData();
  formData.append('file', embeddingBlob, fileName);
  formData.append('projectId', projectId.toString());
  formData.append('imgName', imgName);

  const response = await fetch(`${serverApi}/save-embedding`, {
    method: 'POST',
    body: formData,
  });
}
const getPointsFromBox = (box: modelInputProps) => {
  if (box.width === null || box.height === null) return;
  const upperLeft = { x: box.x, y: box.y };
  const bottomRight = { x: box.width, y: box.height };
  return { upperLeft, bottomRight };
};

const isFirstClick = (clicks: Array<modelInputProps>) => {
  return (
    (clicks.length === 1 && clicks[0].clickType === 1) ||
    (clicks.length === 2 && clicks.every((c) => c.clickType === 2))
  );
};

const modelData = ({
  clicks,
  tensor,
  modelScale,
  last_pred_mask,
}: modeDataProps) => {
  if(tensor==undefined){
    return
  }
  const imageEmbedding = tensor;
  let pointCoords: Float32Array|undefined;
  let pointLabels: Float32Array|undefined;
  let pointCoordsTensor: typeof Tensor | undefined;
  let pointLabelsTensor: typeof Tensor | undefined;

  // Check there are input click prompts
  if (clicks) {
    let n = clicks.length;
    const clicksFromBox = clicks[0].clickType === 2 ? 2 : 0;

    // If there is no box input, a single padding point with
    // label -1 and coordinates (0.0, 0.0) should be concatenated
    // so initialize the array to support (n + 1) points.
    pointCoords = new Float32Array(2 * (n + 1));
    pointLabels = new Float32Array(n + 1);

    // Check if there is a box input
    if (clicksFromBox) {
      pointCoords = new Float32Array(2 * (n + clicksFromBox));
      pointLabels = new Float32Array(n + clicksFromBox);
      const { upperLeft, bottomRight } = getPointsFromBox(clicks[0])!;
     
      pointCoords = new Float32Array(2 * (n + clicksFromBox));
      pointLabels = new Float32Array(n + clicksFromBox);
      if(upperLeft &&
        upperLeft.x && 
        upperLeft.y && 
        bottomRight &&
        bottomRight.x &&
        bottomRight.y &&
      
        modelScale?.samScale){
          pointCoords = new Float32Array(2 * (n + clicksFromBox));
          pointLabels = new Float32Array(n + clicksFromBox);
        pointCoords[0] = upperLeft.x * modelScale?.samScale;
        pointCoords[1] = upperLeft.y * modelScale.samScale;
        pointLabels[0] = 2.0; // UPPER_LEFT
        pointCoords[2] = bottomRight.x * modelScale.samScale;
        pointCoords[3] = bottomRight.y * modelScale.samScale;
        pointLabels[1] = 3.0; // BOTTOM_RIGHT
  
        last_pred_mask = null
      }
     
    }

    // Add regular clicks and scale to what SAM expects
    for (let i = 0; i < n; i++) {
      
      const samScale = modelScale?.samScale ?? 1; // Default to 1 if undefined

        pointCoords[2 * (i + clicksFromBox)] = clicks[i].x??1 * samScale;
        pointCoords[2 * (i + clicksFromBox) + 1] = clicks[i].y??1 * samScale;
          
        pointLabels[i + clicksFromBox] = clicks[i].clickType??0;
     
    }

    // Add in the extra point/label when only clicks and no box
    // The extra point is at (0, 0) with label -1
    if (!clicksFromBox) {
      pointCoords[2 * n] = 0.0;
      pointCoords[2 * n + 1] = 0.0;
      pointLabels[n] = -1.0;
      // update n for creating the tensor
      n = n + 1;
    }

    // Create the tensor
    pointCoordsTensor = new Tensor("float32", pointCoords, [
      1,
      n + clicksFromBox,
      2,
    ]);
    pointLabelsTensor = new Tensor("float32", pointLabels, [
      1,
      n + clicksFromBox,
    ]);
  }
  const imageSizeTensor = new Tensor("float32", [
    modelScale?.height??0,
    modelScale?.width??0,
  ]);

  if (pointCoordsTensor === undefined || pointLabelsTensor === undefined)
    return;

  // If there is a previous tensor, use it, otherwise we default to an empty tensor
  const lastPredMaskTensor =
    last_pred_mask && clicks && !isFirstClick(clicks)
      ? last_pred_mask
      : new Tensor("float32", new Float32Array(256 * 256), [1, 1, 256, 256]);

  // +!! is javascript shorthand to convert truthy value to 1, falsey value to 0
  const hasLastPredTensor = new Tensor("float32" , [
    +!!(last_pred_mask && clicks && !isFirstClick(clicks)),
  ]);

  return {
    image_embeddings: imageEmbedding,
    point_coords: pointCoordsTensor,
    point_labels: pointLabelsTensor,
    orig_im_size: imageSizeTensor,
    mask_input: lastPredMaskTensor,
    has_mask_input: hasLastPredTensor,
  };
};

export { setParmsandQueryModel, modelData };
