import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addImageUrl, getUserImageFile, getUserNewProject } from '../../../slice/user/UserProject'
import { UploadImageS3Bucket } from '../../../api/projects/ProjectApi'
import { getUserLogin } from '../../../slice/user/UserSlice'
import Compressor from 'compressorjs';
type Props={
    resetUploadImage:(data:string)=>void
}

const UploadImageS3Api = ({ resetUploadImage}:Props) => {


    const getUserNewProjects= useSelector(getUserNewProject)
    const getUserImageFiles= useSelector(getUserImageFile)
      const getUserLogins= useSelector(getUserLogin)
        const dispatch= useDispatch();
        const isApi=useRef(true)
 


     useEffect(()=>{
        if(getUserNewProjects &&
             getUserNewProjects.id &&
              getUserImageFiles &&
              getUserLogins &&
              getUserLogins[0] &&
              getUserLogins[0].role &&
              isApi.current
            ){

              const id=  getUserNewProjects.id
           isApi.current=false
                const formData= new FormData;
                const imgName=getUserImageFiles.name
                    const extensionOnly=imgName.split('.').pop()
                    const imagename=`image_${id}_original.${extensionOnly}`
                formData.append('image',getUserImageFiles)
                if( id) formData.append('id', id.toString())
                  formData.append("imagename",imagename)
              if (getUserImageFiles.size > 5 * 1024 * 1024) {  // 5MB in bytes
                handleCompressedUpload(getUserImageFiles, id, imagename, getUserLogins[0].role)
              } else {
                upLoadImage(formData,getUserLogins[0].role, imagename)
              }
               

                   
        }
     },[getUserNewProjects,getUserImageFiles,getUserLogins])



     const handleCompressedUpload = (imageFile:File, id:number,imagename:string , role:string) => {
      const image = imageFile
      new Compressor(image, {
        quality: 0.99,
        width: 1920, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
           
                  const formData= new FormData;
                  const imgName=imageFile.name
                  const extensionOnly=imgName.split('.').pop()
                  const imagename=`image_${id}_compress.${extensionOnly}`
              formData.append('image',compressedResult)
              if( id) formData.append('id', id.toString())
                formData.append('imagename',imagename)

         upLoadImage(formData,role,imagename)
        },
      });
    };


    const upLoadImage=async(data:FormData, role:string,imagename:string)=>{

        try{
             const response= await UploadImageS3Bucket (data, role)

                if(response && response.status===200){
                  
                    dispatch(addImageUrl(imagename))
                    resetUploadImage(imagename)
                    isApi.current=true
                }
        } catch(err){
          isApi.current=true
            alert("Error in upload Image")
            resetUploadImage("fail")
        }
    }
      
 
  return (
    <div></div>
  )
}

export default UploadImageS3Api