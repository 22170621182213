import React, { useEffect } from 'react'
import { PromptModel } from '../../../../Model/genAiAttributes/GenAiAttributes'
import { GeneratePromptImage } from '../../../../api/genAi/GenAiAPi'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { ErrorResponseModel } from '../../../../Model/error/BackendErrorModel'
import { AxiosError, AxiosResponse } from "axios";

type Props={

    selectedDropdownValues:PromptModel
    resetPrompt:(data:string)=>void
}
const GetPrompt = ({selectedDropdownValues,resetPrompt}:Props) => {

     const dispatch= useDispatch()
    useEffect(()=>{
        if(selectedDropdownValues  ){
            generatePrompt(selectedDropdownValues)
        }
        
    },[selectedDropdownValues])

    const generatePrompt=async(selectedDropdownValues:PromptModel)=>{
        try{
            dispatch(addMessage({
                        isShow:true,
                        mess:"Generarting prompt",
                         toastType:"success"
                       }))
                    
            const response = await GeneratePromptImage(selectedDropdownValues) 
                 console.log("response Prompt",response)
                 if(response && response.status==200){
                   
                    resetPrompt(response.data[0])
                 }
                 

                }

                catch (err: unknown) {
                 const error = err as AxiosError
                 if(error.response){
                    const data= error as ErrorResponseModel
                    if(data){
                        const details= data.response.data.detail
                        if(details && details.length>0){
                            const message= details[0].msg
                            let allvaue=""
                            const messDetails=details[0].loc.map(item=>{
                              allvaue+=`${item}-`
                            })
                            dispatch(addMessage({
                                        isShow:true,
                                        mess:`${message}-${allvaue}`,
                                         toastType:"Error"
                                       }))

                        }
                    }
                 }
                }
                
}
  return (
    <div>
        
        
    </div>
  )
}

export default GetPrompt