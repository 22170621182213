import React from 'react'
import {  Modal } from 'react-bootstrap'
import { Button } from '../../3dCanvas/Button'


type Props={
    isShow:boolean,
    onClose:()=>void
    ok:()=>void
}
const ReAnnoatationModal = ({isShow,onClose,ok}:Props) => {

    const handleClose=()=>{
        onClose()
    }

    const handleOk=()=>{
      ok()
    }
  return (
    <div>
        <Modal
        show={isShow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
         <h5 className='model-title'>Confirmation </h5>
        </Modal.Header>
        <Modal.Body>
            <p className='fs-5 fw-medium'>Do you want to save annotation?</p>
        </Modal.Body>
        <Modal.Footer>
        <button className='btn btn-tranparent border-primary text-primary'  onClick={handleClose}>
            Close
          </button>

        <button className='btn btn-primary' onClick={handleOk}
         >Yes</button>

        
         
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ReAnnoatationModal