import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import "./Comment.scss"
import { closeMainCanvas, getCavasGroupComments, getComment } from '../../../../slice/projectCommet/ProjectCommentSlice'
import { MessageModel } from '../../../../Model/comments/CommentsModel'


type Props={
  addMessage:(data:MessageModel)=>void
}
const MainComments = ({addMessage}:Props) => {
    const [isPersonActive, setIsPersonActive] = useState(false);
    const getUserLogins= useSelector(getUserLogin)
    const [selectedUser, setSelectedUser] = useState<string|null>(null);
    const [userName, setUserName]= useState<string|null>(null)
    const [userEmail, setUserEmail]= useState<string|null>(null)
    const [loginDate, setLoginDate]= useState<string|null>(null)
    const [message, setMessage]= useState<string|null>(null)
    const [showUserList, setShowUserList] = useState(false);
    const dispatch= useDispatch()
    
    const users = ["John Doe", "Jane Smith", "Alex Johnson"]; // Example user list
    const getCavasGroupCommentss= useSelector(getCavasGroupComments)// get 
    const getComments= useSelector(getComment)
    const[pointerX, setpointerX]=useState<number|null>(0)
    const[pointerY, setpointerY]=useState<number|null>(0)
    const[userId, setUserId]=useState<number|null>(null)
   
       // set userName and LoginDate
       useEffect(()=>{
        if(getUserLogins && 
          getUserLogins.length>0){
          const name= getUserLogins[0].name
          const email= getUserLogins[0].email
          
          const newDate= new Date()
          const formattedDate = newDate.toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false // 24-hour format
        }).replace(',', '');
          console.log("newDate",formattedDate)
          if(name && newDate && email && getUserLogins[0].id){
            setUserId(getUserLogins[0].id)
            setUserName(name)
            setLoginDate(formattedDate)
            setUserEmail(email)
          }
         
        }
      },[getUserLogins])

      
    const handleAtClick = () => {
        setShowUserList(true); // Show the user list when @ is clicked
      };

      const handleUserSelect = (user: string) => {
        setSelectedUser(user);
       setIsPersonActive(true); // Activate the person button when a user is selected
       setShowUserList(false); // Hide the user list after selection
      };
    
      const handleCloseMainCanvas=()=>{
        if(message && userId && userName){
          const data={
            message:message,
            userId:userId,
            name:userName
          }
       
          if(data){
            addMessage(data)
          }
        }
       
        

      }
  
    const [userLetter, setUserLetter]= useState<string|null>(null)

      return (
        <>



          {getCavasGroupCommentss && 
            getCavasGroupCommentss.isMainComment && 
            getCavasGroupCommentss.pointer && 
            getCavasGroupCommentss.pointer.x && 
            getCavasGroupCommentss.pointer.y && 
            getComments.iscomment &&
            (
              // Comment section style
              <div className='comment-sections' 
              style={{
                transform: `translate(${getCavasGroupCommentss.pointer.x}px, ${getCavasGroupCommentss.pointer.y}px)`,
                transition: 'transform 0.3s ease-in-out',
                // border: "2px solid red", // Border style
              }}
              
              >
                    <div className="d-flex align-items-start group">
  
  <div className="UserAvatar d-flex ">
    <div className="avatarCircle 1 rounded-circle bg-primary text-white">
      <p className="m-0">V</p>
    </div>
  </div>
                  </div>

           
  


              
              <div
                className='bg-white Comment-sec  p-2'
               
              >
                
            


                <form className='rounded-3 w-100 comment-form p-2'>
                  <textarea
                    className="form-control from-textarea"
                    id="exampleFormControlTextarea1"
                    rows={4}
                    value={message ?? ""}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
      
                  <div className='d-flex align-item-center justify-content-between comment-outline pt-2 border-top'>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                      <div className="d-flex align-items-center position-relative">
                        {/* @ button */}
                        <span
                          aria-label="Tag User"
                          className="btn bg-transparent border-0 tag-user"
                          onClick={handleAtClick}
                        >
                          @
                        </span>
      
                        {/* Conditionally show user list */}
                        {showUserList && (
                          <ul className="user-dropdown position-absolute list-unstyled bg-white border">
                            {users.map((user, index) => (
                              <li
                                key={index}
                                className="dropdown-item p-1"
                                onClick={() => handleUserSelect(user)}
                                style={{ cursor: "pointer" }}
                              >
                                {user}
                              </li>
                            ))}
                          </ul>
                        )}
      
                        <button
                          aria-label="Add Person"
                          className={`btn bg-transparent border-0 p-0 ms-2 ${isPersonActive ? "active-user" : ""}`}
                        >
                          <i className="bi bi-person-bounding-box"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <button
                  className='btn rounded-pill bg-primary text-white check-btn'
                  onClick={handleCloseMainCanvas} // Ensure to add the onClick event here
                >
                  <i className="bi bi-check2"></i>
                </button>
              </div>
              </div>
            )}
        </>
      );
      
    }
export default MainComments