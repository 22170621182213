import axios from "axios";
import { ProjectModel, UpdateProjectModel, UploadImageModel } from "../../Model/project/ProjectModel";

 const serverApi =process.env.REACT_APP_SERVER_URL
// const serverApi getUserLogin="https://ztrbpzguq1.execute-api.us-east-2.amazonaws.com/dev"

export function GetallProject() {

   // return axios.get(`${serverApi}/projects`,)
    return axios.get(`${serverApi}/customer/project`,)
        .then(response => {
            // console.log("project", response)
            return response
        }).catch(error => {
            throw error;
        });


}


export function userProject(projectList:[], role:string){
    return axios.post(`${serverApi}/${role}/project`,projectList)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function userCreateProject(projectList:ProjectModel,email:string,role:string){
    const data={
        projectList:projectList,
        email:email,
        role:role
    }
    return axios.post(`${serverApi}/${role}/create-project`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}


export function UpdateProjectData(data:UpdateProjectModel){

    const data1={
        id:data.id,
        email:data.email,
        image:data.image,
        jobId:data.jobId,
        compressImage:data.compressImage
    }

    return axios.post(`${serverApi}/${data.role}/update-image-project`,data1)
    .then(response => {
        // console.log("update project", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function UploadImageS3Bucket(data:FormData,role:string){
    return axios.post(`${serverApi}/${role}/project/uploadImage`,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${role}`
        }
      })
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function UploadImageS3Bucket1(imageData:UploadImageModel){
   
    return axios.post(`${serverApi}/${imageData.role}/project/uploadImage2`,imageData,{
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   'Authorization': `Bearer ${imageData.role}`
        // }
      })
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}

// update job id into project
export function UpdateJobInProject(projectId:number, jobId:number){
    const data={
        projectId:projectId,
        jobId:jobId
    }
    return axios.post(`${serverApi}/project/update-job`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}


// delete project by Id 
export function ProjectDeleteById(projectId:number){
    const data={
        projectId:projectId,
       
    }
    return axios.post(`${serverApi}/project/delete`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}