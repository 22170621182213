import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CanvasControlModel, GroupMasterSegment, JobSegmentationModel, MasterModel, ReAnnotationModel } from "../../../Model/masterArray/MasterArrayModel";
import { DesignerDetail, SegmentDetails, Swatch } from "../../../Model/Job/SamModel";
import { UpdateIndexModel, UpdateSegregationModel } from "../../../Model/masterArray/UpdateIndexModel";


interface masterArrayStates {
    masterArray:MasterModel,
    hoverSeg:JobSegmentationModel[],
    reAnnotationSegment:ReAnnotationModel, 
    isUpDateJob:boolean,
    isChildHover:boolean,
    canvasControl:CanvasControlModel,
    updateIndex:UpdateIndexModel,
    updateSegregation:UpdateSegregationModel
    hoverGrp:string, 
    noHoverGrp:string
  
    
}

const initialState: masterArrayStates = {
    masterArray:{},
    hoverSeg:[],
    reAnnotationSegment:{},
    isUpDateJob:false,
    isChildHover:false,
    canvasControl:{},
    updateIndex:{},
    updateSegregation:{},
    hoverGrp:"",
    noHoverGrp:""



};

const MasterArraySlice = createSlice({
    name: "masterArray",
    initialState,
    reducers: {
     
      
        addMasterArray:(state,action)=>{
            state.masterArray=action.payload;

        } ,
        addHoverGroup:(state,action)=>{
            state.hoverGrp=action.payload
        },
        addNoHoverGroup:(state,action)=>{
          state.noHoverGrp=action.payload
        },
        resetNoHoverGroup:(state)=>{
          state.noHoverGrp=""
        },
        resetHoverGrp:(state)=>{
              state.hoverGrp=""
        },
        resetMasterArray:(state)=>{
            state.masterArray={}
        }, 
        startSegHoverEffect: (state, action) => {
            const { seg,isChildHover} = action.payload;
             state.hoverSeg=seg
             state.isChildHover=isChildHover
        }, 
        stopSegHoverEffect:(state)=>{
            state.hoverSeg=[]
        },
        addSwatchDetail: (state, action) => {
            const { segName, swatchData, masterSeg, userId } = action.payload;
            const allseg = state.masterArray.allSeg;
          
            // Find the index of the master segment
            const masterIndex = allseg?.findIndex(item => item.name === masterSeg);
          
            if (typeof masterIndex === 'number' &&masterIndex !== -1 && allseg) {
              const segmentation = allseg[masterIndex]?.segmentation;
          
             
            }
          },
          deleteSegment: (state, action) => {
            const { groupName,subGroupName,childName,childShortName } = action.payload;
                
            const allSeg = state.masterArray.allSeg?.find(item => item.name === groupName);
            const masterSegmentation = allSeg?.segmentation;
        
            if (masterSegmentation && masterSegmentation.length > 0) {
                const groupIndex = masterSegmentation.findIndex(item =>
                    Object.keys(item).includes(subGroupName)
                );
      
                if (groupIndex !== -1) {
                    const selectedGroup = masterSegmentation[groupIndex][subGroupName];
                    if (Array.isArray(selectedGroup) && selectedGroup.length > 0) {
                        const segIndex = selectedGroup.findIndex(item => item.segName === childName);
                        if (segIndex !== -1) {
                            selectedGroup.splice(segIndex, 1);  // Remove the segment
        
                           
                        }
                    }
                }
            }
              state.updateIndex.groupName=groupName
              state.updateIndex.subGroupName=subGroupName
              state.updateIndex.childname=childName
              state.updateIndex.seg_short=childShortName
              state.updateIndex.isDeleteSegFromcanvas= true
     

        },
        resetisDeleteSegFromcanvas:(state)=>{
          state.updateIndex.isDeleteSegFromcanvas= false
        },
        
           addReAnnotationSegment:(state,action)=>{
            const {groupName,segName,name}= action.payload

            state.reAnnotationSegment.groupName= groupName
            state.reAnnotationSegment.name= name
            state.reAnnotationSegment.segName= segName
           },
          
           updateReAnnotation:(state,action)=>{
              const {editedData} =action.payload
              state.reAnnotationSegment.editedData=editedData
           },
           resetReAnnotation:(state)=>{
             state.reAnnotationSegment={}
           }
           ,
           updateAnnotationPoint: (state, action) => { 
            const { segName, childgroupName ,seg_short,groupName,masterData} = action.payload;
            const modifiedSegName = segName.replace(/[0-9]/g, "");
            
            // Check if allSeg exists before proceeding
            if (state.masterArray.allSeg) {
                const allSeg = state.masterArray.allSeg.find(item => item.name === modifiedSegName);
                const masterSegmentation = allSeg?.segmentation;
        
                if (masterSegmentation && masterSegmentation.length) {
                    // Find the index of the group within masterSegmentation
                    const groupIndex = masterSegmentation.findIndex(item =>
                        Object.keys(item).includes(childgroupName)
                    );
                        
                    if (groupIndex !== -1) {
                        const segmentValue = masterSegmentation[groupIndex][childgroupName];
        
                        if (Array.isArray(segmentValue) && segmentValue.length > 0) {
                            // Find the segment with the specified segName
                            const segIndex = segmentValue.findIndex(item => item.segName === segName);
        
                            if (segIndex !== -1) {
                                const details = segmentValue[segIndex].details;
        
                                if (details) {
                                    // Update the details
                                    details.annotation_type="manual"
                                    details.annotation=masterData.annotation
                                    details.bb_annotation_float=masterData.bb_annotation_float
                                    details.bb_annotation_int=masterData.bb_annotation_int
                                    details.bb_dimension_pixel=masterData.bb_dimension_pixel
                                    details.seg_dimension_pixel=masterData.seg_dimension_pixel||[]
                                    details.perimeter_pixel=masterData.perimeter_pixel??0
                                    details.perimeter_feet=masterData.perimeter_feet??0
                                    details.top_coordinate=masterData.top_coordinate??[]
                                    details.annotation_area_pixel=masterData.annotation_area_pixel??0
                                    details.annotation_area_sqft=masterData.annotation_area_sqft??0
                                    details.dimension_seg=masterData.dimension_seg??[]
                                    details.long_trim_seg_dist=masterData.long_trim_seg_dist??[]
                                    details.middle_points=masterData.middle_points??[]
                                    details.bb_area_pixel=masterData.bb_area_pixel
                                    details.bb_area_sqft=masterData.bb_area_sqft??0
                                    details.bb_dimension_pixel=masterData.bb_dimension_pixel
                                    details.bb_dimension_feet=masterData.bb_dimension_feet??[]
                                }
                            }
                            if(segIndex == -1){
                              const details:SegmentDetails={
                                annotation_type:"manual",
                                annotation:masterData.annotation,
                                bb_annotation_int:masterData.bb_annotation_int,
                                bb_annotation_float:masterData.bb_annotation_float,
                                group:childgroupName,
                                seg_type:groupName,
                                label:segName,
                                seg_name:segName,
                                seg_short:seg_short,
                                seg_dimension_pixel:masterData.seg_dimension_pixel||[],
                                perimeter_pixel:masterData.perimeter_pixel||0,
                                perimeter_feet:masterData.perimeter_feet||0,
                                top_coordinate:masterData.top_coordinate||[],
                                annotation_area_pixel:masterData.annotation_area_pixel||0,
                                annotation_area_sqft:masterData.annotation_area_sqft||0,
                                dimension_seg:masterData.dimension_seg||[],
                                long_trim_seg_dist:masterData.long_trim_seg_dist??[],
                                middle_points:masterData.middle_points??[],
                                bb_area_pixel:masterData.bb_area_pixel,
                                bb_area_sqft:masterData.bb_area_sqft??0,
                                bb_dimension_pixel:masterData.bb_dimension_pixel,
                                bb_dimension_feet:masterData.bb_dimension_feet??[]
            
                              }
                              segmentValue.push({
                                segName:segName,
                                name:seg_short,
                               details:details,
                               designer:[],
                               overAllSwatch:[]

                              })
                            }
                        }else if(segmentValue.length==0){
                          
                          const detail:SegmentDetails={
                            annotation_type:"manual",
                            annotation:masterData.annotation,
                            bb_annotation_int:masterData.bb_annotation_int,
                            bb_annotation_float:masterData.bb_annotation_float,
                            group:childgroupName,
                            seg_type:groupName,
                            label:segName,
                            seg_name:segName,
                            seg_short:seg_short,
                            seg_dimension_pixel:masterData.seg_dimension_pixel||[],
                            perimeter_pixel:masterData.perimeter_pixel||0,
                            perimeter_feet:masterData.perimeter_feet||0,
                            top_coordinate:masterData.top_coordinate||[],
                            annotation_area_pixel:masterData.annotation_area_pixel||0,
                            annotation_area_sqft:masterData.annotation_area_sqft||0,
                            dimension_seg:masterData.dimension_seg||[],
                            long_trim_seg_dist:masterData.long_trim_seg_dist??[],
                            middle_points:masterData.middle_points??[],
                            bb_area_pixel:masterData.bb_area_pixel,
                            bb_area_sqft:masterData.bb_area_sqft??0,
                            bb_dimension_pixel:masterData.bb_dimension_pixel,
                            bb_dimension_feet:masterData.bb_dimension_feet??[]
        
                          }
                          segmentValue.push({
                            segName:segName,
                            name:seg_short,
                           details:detail,
                           designer:[],
                           overAllSwatch:[]
    
                          })
                        }
                    } else if(groupIndex==-1){

                     
                    }
                }
            }
            state.isUpDateJob=true
        },
        
           addGroupOnSegmentation: (state, action) => {
            const { segmentGroupName, groupName } = action.payload;
            const segGroups = state.masterArray.allSeg;
            if (segGroups) {
              const segIndex = segGroups.findIndex(item => item.name === segmentGroupName);   
              if (segIndex !== -1) {
                const segGroup = segGroups[segIndex];
                if (!segGroup.segmentation ) {
                  segGroup.segmentation = [];  // Initialize if undefined
                }
                const groups = segGroup.segmentation;
                
                 if(groups && groups.length>0){
                  groups.push({
                    [groupName]:[]
                  })
                 }
              }
            }
          },
          updatechildrenSegment: (state, action) => {
            const { groupName, subGroupName, child, preGroupName } = action.payload;
          
            const segGroups = state.masterArray.allSeg;
            if (segGroups) {
              const segIndex = segGroups.findIndex(item => item.name === groupName);
              if (segIndex !== -1) {
                const segGroup = segGroups[segIndex];
          
                // Initialize segmentation if it's undefined
                if (!segGroup.segmentation) {
                  segGroup.segmentation = [];
                }
          
                const groups = segGroup.segmentation ;
          
                if (groups && groups.length > 0) {
                  const groupIndex = groups.findIndex(item =>
                    Object.keys(item as Record<string, unknown>).includes(subGroupName)
                  );
          
                  const PregroupIndex = groups.findIndex(item =>
                    Object.keys(item).includes(preGroupName)
                  );
           
                  if (PregroupIndex !== -1 &&groupIndex!=-1) {
                    const preGroup = groups[PregroupIndex][preGroupName];
                    const childValue = preGroup.find(seg => seg.name === child.title);
                      
                    if (childValue) {
                      const childkey = groups[groupIndex][subGroupName]
                      const updatedChildValue = {
                          ...childValue,
                          details: {
                            ...childValue.details,
                            group: subGroupName, // Update the group
                          },
                        };            
           const updatedSubGroup = [...childkey, updatedChildValue];
          
                   
                        // Update the segmentation with the new subgroup
                        const updatedSegmentation = groups.map((item, idx) => {
                          if (idx === groupIndex) {
                              return {
                                  ...item,
                                  [subGroupName]: updatedSubGroup,
                              };
                          }
                          return item;
                      });

                      // Update the segmentation array in the state
                      segGroup.segmentation = updatedSegmentation;
                     
                     
                    }
                  }
                }
              }
            }
          }
          ,
          
          DeleteAterAddChildSegment:(state,action)=>{
            const { groupName, subGroupName, child, preGroupName } = action.payload;
          
            const segGroups = state.masterArray.allSeg;
            if (segGroups) {
              const segIndex = segGroups.findIndex(item => item.name === groupName);
              if (segIndex !== -1) {
                const segGroup = segGroups[segIndex];
          
                // Initialize segmentation if it's undefined
                if (!segGroup.segmentation) {
                  segGroup.segmentation = [];
                }
          
                const groups = segGroup.segmentation;
          
                if (groups && groups.length > 0) {
                  
          
                  const PregroupIndex = groups.findIndex(item =>
                    Object.keys(item).includes(preGroupName)
                  );
           
                  if (PregroupIndex !== -1 ) {
                    const preGroup = groups[PregroupIndex][preGroupName];
                    const childIndex= preGroup.findIndex(seg => seg.name === child.title);
                       if(childIndex!=-1){
                          // Remove the child segment from the preGroup
                          const updatedPreGroup = [
                            ...preGroup.slice(0, childIndex),
                            ...preGroup.slice(childIndex + 1)
                        ];

                        // Update the segmentation with the modified preGroup
                        const updatedSegmentation = groups.map((item, idx) => {
                            if (idx === PregroupIndex) {
                                return {
                                    ...item,
                                    [preGroupName]: updatedPreGroup
                                };
                            }
                            return item;
                        });

                        // Update the segmentation array in the state
                        segGroup.segmentation = updatedSegmentation;
                       }
                    
            
                    
                  }
                }
              }
            }
            state.isUpDateJob=true
          },
          startUpdateJob:(state)=>{
            state.isUpDateJob= true
          },

          resetJobUpdate:(state)=>{
            state.isUpDateJob= false
          },
          updateSwatchImage:(state,action)=>{
            const{swatchImage,userId,swatchId,swatchTitle,swatchPhoto,groupName,subGroupName}=action.payload
             
            const groups= state.masterArray.allSeg
            if(groups && groups.length>0){

               const groupIndex= groups.findIndex(item=>item.name===groupName)
                 if(groupIndex!=-1){
                   
                  const overAll=groups[groupIndex].overAllSwatch
                    const allSubGroup=groups[groupIndex].segmentation

                    if(overAll &&overAll.length==0){
                      const swatchData={
                        swatch_id: swatchId,
                        title:swatchTitle,
                        photo:swatchPhoto,
                        cost: {},
                        isActive: true,
                        isApproved: false
                      }
                      overAll.push(swatchData)
                    }
                    else if( overAll&&overAll?.length>0){
                      // prevent dublicacy
                       const swatchIndex= overAll?.findIndex(item=>item.swatch_id===swatchId)  ;
                        // upddate if not exits

                       if(swatchIndex==-1){
                        const swatchData={
                        swatch_id: swatchId,
                        title:swatchTitle,
                        photo:swatchPhoto,
                        cost: {},
                        isActive: true,
                        isApproved: false
                      }
                      overAll.push(swatchData)
                       } 

                    }


                    //update in designer
            
                    if(allSubGroup && allSubGroup.length>0){
                        const subGrpIndex= allSubGroup.findIndex(grp=>
                          Object.keys(grp).includes(subGroupName)
                        )
                        if(subGrpIndex!=-1){
                          const childgroup=allSubGroup[subGrpIndex][subGroupName]
                           if(Array.isArray(childgroup) && childgroup.length > 0){
                               
                                Object.keys(swatchImage).forEach(segImg=>{
                                      const childSegIndex=  childgroup.findIndex(item=>item.segName==segImg)
                                        if(childSegIndex!=-1){
                                          const segDesigner= childgroup[childSegIndex].designer
                                          if(segDesigner && segDesigner.length===0){
                                            const swatchData={
                                              swatch_id: swatchId,
                                              swatch_seg_image: swatchImage[segImg],
                                              title:swatchTitle,
                                              photo:swatchPhoto,
                                              cost: {},
                                              isActive: true,
                                              isApproved: false
                                            }
                                            const useData={
                                              user_id:userId ,
                                              swatch:  [swatchData] 
                                            }
                                            segDesigner.push(useData)

                                          } else if (segDesigner && segDesigner.length>0){
                                               const eachDesigner= segDesigner.find(desgn=>desgn.user_id===userId)
                                                 if(eachDesigner){
                                                    const dsignerAccount=eachDesigner.swatch as Swatch[]
                                                    if(dsignerAccount?.length==0)
                                                      {
                                                      const swatchData={
                                                        swatch_id: swatchId,
                                                        swatch_seg_image: swatchImage[segImg],
                                                        title:swatchTitle,
                                                        photo:swatchPhoto,
                                                        cost: {},
                                                        isActive: true,
                                                        isApproved: false
                                                      }
                                                      dsignerAccount.push(swatchData)
                                                    }
                                                    if(dsignerAccount?.length>0){
                                                      const designerSwatchIndex=dsignerAccount?.findIndex(dgnSwatch=>dgnSwatch.swatch_id==swatchId) ;
                                                        if(designerSwatchIndex!=-1){
                                                          dsignerAccount[designerSwatchIndex].swatch_seg_image=swatchImage[segImg]
                                                        }else{
                                                          const swatchData={
                                                            swatch_id: swatchId,
                                                            swatch_seg_image: swatchImage[segImg],
                                                            title:swatchTitle,
                                                            photo:swatchPhoto,
                                                            cost: {},
                                                            isActive: true,
                                                            isApproved: false
                                                          }
                                                          dsignerAccount.push(swatchData)
                                                        }
                                                    }
                                                 }else{
                                                  const swatchData={
                                                    swatch_id: swatchId,
                                                    swatch_seg_image: swatchImage[segImg],
                                                    title:swatchTitle,
                                                    photo:swatchPhoto,
                                                    cost: {},
                                                    isActive: true,
                                                    isApproved: false
                                                  }
                                                  const useData={
                                                    user_id:userId ,
                                                    swatch:  [swatchData] 
                                                  }
                                                  segDesigner.push(useData)
                                                 }
                                          }
                                        }
                                        
                   

                                })
                                  
                            
                           }
                        }
                    }
                 }
            }

          }, 
          updateIndexing:(state,action)=>{
            const {groupName, subGroupName,childName}= action.payload
            const allSeg= state.masterArray.allSeg
            if(allSeg&&allSeg?.length>0){
              const selectedGroupIndex= allSeg.findIndex(item=>item.name===groupName)
             const selectedGroup=allSeg[selectedGroupIndex].segmentation
             const short_code=allSeg[selectedGroupIndex].short_code
              if(selectedGroup && selectedGroupIndex!=-1 && short_code){
                let i=0;
                const allSubGroup=selectedGroup
                  if(allSubGroup && allSubGroup.length>0){

                       allSubGroup.map(item=>{
                        Object.keys(item).forEach((subGroup,index)=>{

                          const value=item[subGroup]
                          if(value && value.length>0){
                            value.map(grp=>{
                              i=i+1
                              const shortName= grp.details?.seg_short
                              if(shortName &&grp.details?.label) {
                                const indexString = i.toString();
                                grp.name = `${short_code}${indexString}`;
                                grp.segName = `${groupName}${indexString}`;
                                grp.details.label = `${groupName}${indexString}`;
                                grp.details.seg_name = `${groupName}${indexString}`;
                                grp.details.seg_short = `${short_code}${indexString}`;
                              }
                               

                            })
                           
                          }
                          
                        })
                       })
                  }

              }
                state.isUpDateJob= true
                state.updateSegregation.isUpdateSegregation= true
                state.updateSegregation.groupName=groupName
                state.updateSegregation.childName=childName
            }
            
            
          }
          ,
          resetUpdateIndexing:(state)=>{
            state.updateIndex={}
            state.isUpDateJob=false
          }, 
          resetUpdateSegragation:(state)=>{
            state.updateSegregation={}
          }
          
          

    }
}
)

export const {addMasterArray,resetMasterArray,
    startSegHoverEffect, stopSegHoverEffect,
    addSwatchDetail,deleteSegment,addReAnnotationSegment,
    updateAnnotationPoint,addGroupOnSegmentation,
    updatechildrenSegment,DeleteAterAddChildSegment,
    resetJobUpdate,startUpdateJob, updateReAnnotation,resetReAnnotation,
    updateSwatchImage,updateIndexing,resetUpdateIndexing,
    resetisDeleteSegFromcanvas, resetUpdateSegragation,
    addHoverGroup,addNoHoverGroup,resetHoverGrp,resetNoHoverGroup
}=MasterArraySlice.actions;
export const getMasterArray=  (state:{masterArray:masterArrayStates})=>state.masterArray.masterArray
export const getHoverSeg=  (state:{masterArray:masterArrayStates})=>state.masterArray.hoverSeg
export const getReAnnotationSegment=  (state:{masterArray:masterArrayStates})=>state.masterArray.reAnnotationSegment
export const getIsUpdateJob=  (state:{masterArray:masterArrayStates})=>state.masterArray.isUpDateJob
export const getUpdateIndexing= (state:{masterArray:masterArrayStates})=>state.masterArray.updateIndex
export const getUpdateSegregation= (state:{masterArray:masterArrayStates})=>state.masterArray.updateSegregation
export const getHoverGroup= (state:{masterArray:masterArrayStates})=>state.masterArray.hoverGrp
export const getNoHoverGroup= (state:{masterArray:masterArrayStates})=>state.masterArray.noHoverGrp
export default MasterArraySlice.reducer


