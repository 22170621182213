import axios from 'axios'
import React, { useEffect } from 'react'
import { GetallProject, userProject } from '../../../api/projects/ProjectApi'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../../../slice/user/UserSlice'
import { userModel } from '../../../Model/user/UserModel'
import { addUserProject } from '../../../slice/user/UserProject'


const GetAllProject = () => {
   const dispatch= useDispatch();
   const getUserLogins= useSelector(getUserLogin) as userModel[]

     useEffect(()=>{
      if(getUserLogins && getUserLogins.length>0){
         
         const projectList=getUserLogins[0].projects
         const role= getUserLogins[0].role?getUserLogins[0].role:""
         getuserProjectS(projectList?projectList:[], role)
      }
       
     },[getUserLogins])

    const getuserProjectS=async (projectList:[], role:string)=>{
      
         try{
            
            const projects= await userProject(projectList, role);
            // console.log("project", projects)
            if(projects.status===200 && projects.data.length>0){
   
               dispatch(addUserProject(projects.data))
            }
           

         }
         catch(err){
            console.log("error in getting project")
         }
    }
  return (
    <div></div>
  )
}

export default GetAllProject