import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SegmentModel } from "../../Model/segment/SegmentModel";

interface SegmentStates {
    segment:SegmentModel[]
   
    
}

const initialState: SegmentStates = {
    segment:[]

};

const SegmentSlice = createSlice({
    name: "segment",
    initialState,
    reducers: {
        addSegment:(state,action)=>{
            state.segment= action.payload
        }, resetSegment:(state)=>{
            state.segment=[]
        }
        
      }

    
}
)

export const {addSegment,resetSegment}=SegmentSlice.actions;
export const getAllSegment=  (state:{segment:SegmentStates})=>state.segment.segment
export default SegmentSlice.reducer