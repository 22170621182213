import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addMaterialCategory, getMaterialCategorys } from '../../slice/materialSlice/MaterialCategorySlice'
import { GetAllMaterialsCategories } from '../../api/material/category/CategoryApi'

const GetAllMaterialCaterogy = () => {
    

  const dispatch= useDispatch()
  const getMaterialCategory= useSelector(getMaterialCategorys)
   useEffect(()=>{
     if(getMaterialCategory && getMaterialCategory.length===0){
      getAllMaterials_category()
     }
      
   },[getMaterialCategory])
    const getAllMaterials_category= async()=>{
        try{
     
             const response= await GetAllMaterialsCategories()
                 if(response && response.status===200){
                  dispatch(addMaterialCategory(response.data))
                 }
        }catch(err){
           console.log("err in getting all material")
        }

    }
  return (
    <div></div>
  )
}

export default GetAllMaterialCaterogy