// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.from-btn{
    background-color: #6d455a !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 6px 12px !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
}
.from-save-btn{

    background-color: #293849 !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 6px 12px !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
}
.btn-from{text-align: end; margin-top: 25px;}
.radio-btn .css-1cqh9jq{margin-right: 20px; }
/* .radio-button-from .css-1cqh9jq{
background-color: #fff;

} */
.radio-button-from{padding-top: 6px; padding-bottom: 6px;}
.radio-button-from  .css-vtg3j9{    border-color: #293849 !important;
}
.from-btn{    margin-right: 16px;}
.buttonradio{padding-left: 10px;}`, "",{"version":3,"sources":["webpack://./src/module/brandPage/style/EditStyleForm.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,kCAAkC;IAClC,eAAe;IACf,2BAA2B;IAC3B,4BAA4B;IAC5B,6BAA6B;IAC7B,6BAA6B;AACjC;AACA;;IAEI,oCAAoC;IACpC,sBAAsB;IACtB,kCAAkC;IAClC,eAAe;IACf,2BAA2B;IAC3B,4BAA4B;IAC5B,6BAA6B;IAC7B,6BAA6B;AACjC;AACA,UAAU,eAAe,EAAE,gBAAgB,CAAC;AAC5C,wBAAwB,kBAAkB,EAAE;AAC5C;;;GAGG;AACH,mBAAmB,gBAAgB,EAAE,mBAAmB,CAAC;AACzD,oCAAoC,gCAAgC;AACpE;AACA,cAAc,kBAAkB,CAAC;AACjC,aAAa,kBAAkB,CAAC","sourcesContent":[".from-btn{\n    background-color: #6d455a !important;\n    color: #fff !important;\n    font-family: \"Poppins\", sans-serif;\n    font-size: 16px;\n    font-weight: 400 !important;\n    padding: 6px 12px !important;\n    border-radius: 4px !important;\n    border: 1px solid transparent;\n}\n.from-save-btn{\n\n    background-color: #293849 !important;\n    color: #fff !important;\n    font-family: \"Poppins\", sans-serif;\n    font-size: 16px;\n    font-weight: 400 !important;\n    padding: 6px 12px !important;\n    border-radius: 4px !important;\n    border: 1px solid transparent;\n}\n.btn-from{text-align: end; margin-top: 25px;}\n.radio-btn .css-1cqh9jq{margin-right: 20px; }\n/* .radio-button-from .css-1cqh9jq{\nbackground-color: #fff;\n\n} */\n.radio-button-from{padding-top: 6px; padding-bottom: 6px;}\n.radio-button-from  .css-vtg3j9{    border-color: #293849 !important;\n}\n.from-btn{    margin-right: 16px;}\n.buttonradio{padding-left: 10px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
