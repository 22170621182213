// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .pro-image img{ width: 1166px; height: 675px; margin: 0 auto;}
.right-project-layer-img{margin-top: 12px;}
.right-section-img{width: 79%;}
.project-left-section{width: 21%; padding: 0;}

.right-project-layer-img{position: relative;} */`, "",{"version":3,"sources":["webpack://./src/module/ImageView/RightSection/RightSection.css"],"names":[],"mappings":"AAAA;;;;;+CAK+C","sourcesContent":["/* .pro-image img{ width: 1166px; height: 675px; margin: 0 auto;}\n.right-project-layer-img{margin-top: 12px;}\n.right-section-img{width: 79%;}\n.project-left-section{width: 21%; padding: 0;}\n\n.right-project-layer-img{position: relative;} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
