import React, { useEffect, useState } from 'react'
import "../LeftSections.scss"
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { useSelector } from 'react-redux'
import { Swatch } from '../../../../Model/Job/SamModel'


const s3path= process.env.REACT_APP_S3BucketMaterial

type Props={
    groupName:string
}
const CurrentSelectedSwatch = ({groupName}:Props) => {
    const path =`${s3path}/liv/materials`;
     const getMasterArrays= useSelector(getMasterArray)
     const [overallSwatch, setOverAllSwatch]= useState<Swatch[]>([])
      useEffect(()=>{
        if(getMasterArrays && 
            getMasterArrays.allSeg &&
             getMasterArrays.allSeg.length>0 &&
             groupName){
             const segGroup=   getMasterArrays.allSeg.find(item=>item.name==groupName)
                    if(segGroup && segGroup.overAllSwatch &&
                     segGroup.overAllSwatch.length>0
                    ){
                        setOverAllSwatch(segGroup.overAllSwatch)
                    }else{
                        setOverAllSwatch([])
                    }
        }else{
            setOverAllSwatch([])
        }
      },[getMasterArrays,groupName])
  return (
    <>
     <div className='selected-items p-2'>
                          <h6>Current Selected Area</h6>
                          <div className='row g-2'>
                          {overallSwatch && overallSwatch.length > 0 ? (
                            overallSwatch.map((item, index) => (
                                <div className="col-2" key={index}>
                                <div className="palletsbox">
                                    <img src={`${path}/${item?.photo}`}  alt="" />
                                </div>
                                </div>
                            ))
                            ) : (
                            <div>No swatches available</div>)}

                              
                             
                          </div>
                      </div>

    </>
  )
}

export default CurrentSelectedSwatch



 {/* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette3.jpg" alt="" />
                                  </div>
                              </div> */}
                              {/* <div className='col-2'>
                                  <div className='palletsbox active'>
                                      <img src="/img/palette3.jpg" alt="" />
                                      <svg className="checkicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                                          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                                      </svg>
                                  </div>
                              </div> */}
                              {/* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */}
                              {/* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */}
                              {/* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */}