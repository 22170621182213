import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import "./AddGroupModel.scss";
import {
  getGroupList,
  updateChildAddSegment,
  updateGroup,
} from "../../../../slice/canvas/groupSlice.tsx/GroupSlice";
import { useNavigate } from "react-router-dom";
import { AddSubGroupModel, MainGroupModel } from "../../../../Model/groupingModel/GroupingModel";
import {
  addGroupOnSegmentation,
  getMasterArray,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { changeSegmentTab } from "../../../../slice/tabControl/TabControlSlice";
import ReactDOMServer from "react-dom/server";

import { GroupMasterSegment, MasterArrayModel, MasterModel } from "../../../../Model/masterArray/MasterArrayModel";
import AnnotationList from "./AnnotationList";
type Props = {
  isSegmentModal: boolean;
  handleClose: () => void;
  selectedSegment: boolean
  ok: () => void
};
const AddGroupModel = ({
  isSegmentModal,
  selectedSegment,
  handleClose,
  ok

}: Props) => {
  const getGroupLists = useSelector(getGroupList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState<MainGroupModel | null>(null);
  const getMasterArrays = useSelector(getMasterArray);
  const [jobId, setJobId] = useState<number | null>(null);
  const [groupChildName, setGroupChildName] = useState<string | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [subgroupName, setSubGroupName] = useState<string | null>(null);
  const [childName, setChildName] = useState<string | null>(null);
  const [segmentArray, setSegmentArray] = useState<MasterModel | null>(null)
  // get job id
  useEffect(() => {
    if (getMasterArrays && getMasterArrays.jobId) {
      setJobId(getMasterArrays.jobId);
      setSegmentArray(getMasterArrays)
    }
  }, [getMasterArrays]);



  // new SubGroup
  const handleNewChildGroup = () => {
    if (groupList && groupList.groups && groupList.groups.length > 0) {
      const name: string = groupList.title ?? "";

      const newSubGroup = name + (groupList.groups.length + 1);
      console.log("newSubGroup", newSubGroup);
      if (newSubGroup) {
        //add group in groupslice
        dispatch(
          updateGroup({
            group: name,
            groupName: newSubGroup,
          })
        );

        // add group in  master array
        dispatch(
          addGroupOnSegmentation({
            segmentGroupName: name,
            groupName: newSubGroup,
          })
        );
      }
      setGroupChildName(newSubGroup);
    }
  };

  
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{
    [key: number]: number | null;
  }>({});
  // Function to handle submenu click
  const handleSubmenuClick = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
  };


  // popover start
  const iconRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<any>(null); // To store popover instance

  useEffect(() => {
    if (iconRef.current) {
      const bootstrap = require("bootstrap"); // Import Bootstrap JS

      popoverRef.current = new bootstrap.Popover(iconRef.current, {
        html: true,
        content: ReactDOMServer.renderToString(<AnnotationList />),
        placement: 'right',
        customClass: "seg-popover", // Ensure this is not empty
        trigger: 'manual',
      });
    }

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element; // Assert target as Element

      if (iconRef.current && !iconRef.current.contains(target)) {
        popoverRef.current.hide(); // Hide popover if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (popoverRef.current) {
        popoverRef.current.dispose(); // Clean up popover on unmount
      }
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener
    };
  }, []);

  // Function to handle icon click
  const handleIconClick = () => {
    if (popoverRef.current) {
      popoverRef.current.toggle(); // Toggle popover on icon click
    }
  };

  // popover end


  const [openAccordions, setOpenAccordions] = useState<{ [key: number]: boolean }>({});

  const toggleAccordion = (index: number) => {
    setOpenAccordions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleSubAddGroup = (segmentation: GroupMasterSegment[], name: string) => {
    console.log("segmentation", segmentation)
    console.log("name", name)
    const newSubGroup = name + (segmentation.length + 1)
    console.log("newSubGroup", newSubGroup)
    const data: AddSubGroupModel = {
      segmentGroupName: name,
      groupName: newSubGroup
    }
    dispatch(addGroupOnSegmentation(data))
  }

  const handleChildGroup = (group: MasterArrayModel, subGroupName: string) => {
    const allSeg = group.short_code

    dispatch(updateChildAddSegment({
      groupName: group.name,
      childGroupName: subGroupName,
      shortName: group.short_code,
      subGroupLength: allSeg?.length

    }))
    const len=allSeg?.length
    setChildName( group?.short_code??""+(len?len+1:1))
    setSubGroupName(subGroupName)
    setGroupName(group.name??"")
    dispatch(changeSegmentTab("newSegment"))
    console.log("group", group)
    console.log("subGroupName", subGroupName)
  }


  // handle save
  const handleSave = () => {
    console.log("save")
    ok()
  }


  return (
    <>
      <Modal
        show={isSegmentModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="mb-0">Annotation Editor</h5>
            <div className="d-flex align-items-center me-3 ">
              <span className="dot-icon" ref={iconRef} data-bs-toggle="hover">
                <i
                  className="bi bi-three-dots-vertical"
                  ref={iconRef}
                  data-bs-toggle="popover"
                  onClick={handleIconClick} // Attach click handler
                ></i>
              </span>
              {/* <button type="button" className="btn-close" aria-label="Close"></button> */}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="mb-2">
         


         {groupName && 
         subgroupName && 
         childName && 
         <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-section">
                <li className="breadcrumb-item"><a href="#">{groupName}</a></li>
                <li className="breadcrumb-item"><a href="#">{subgroupName}</a></li>
                <li className="breadcrumb-item active" aria-current="page">{childName}</li>
              </ol>
            </nav>
          </div>}
          <div className="actions-btns d-flex align-items-center justify-content-between mt-4 mb-3">
            <button
              className="btn btn-tranparent border-primary text-primary"
              // disabled={!selectedSegment}
              onClick={handleClose}>

              Delete
            </button>
            <button
              className="btn btn-primary"
              // disabled={!selectedSegment}
              onClick={handleSave}>
              Save
            </button>
          </div>



          {segmentArray &&
            segmentArray.allSeg &&
            segmentArray.allSeg.length > 0 &&
            segmentArray.allSeg.map((item, index) => {
              return (
                <div className="accordion accordion-seg-list mt-2 " key={index}>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className={`accordion-button seg-index-btn ${openAccordions[index] ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}>
                        <span className="w-100 m-0 fs-6 fw-semibold">{item.name}</span>
                        <span className="addseg-icon" onClick={() => handleSubAddGroup(item.segmentation || [], item.name ?? "")

                        }>
                          <i className={`bi ${openAccordions[index] ? 'bi-plus' : 'bi-plus'} ms-auto`}></i>
                        </span>
                      </button>
                    </div>

                    {item.segmentation && item.segmentation.length > 0 && (
                      <div
                        className={`accordion-collapse collapse ${openAccordions[index] ? 'show' : ''}`}>
                        <div className="accordion-body p-0">
                          <ul className="seg-group-list p-0 m-0">
                            {item.segmentation.map((seg, segIndex) => {
                              const key = Object.keys(seg)[0];
                              return (

                                <li className="bg-white cursor-move" key={segIndex} onClick={() => handleChildGroup(item, key)}>
                                  <div className="seg-group-title border-top seg-groups-title">{key}</div>
                                </li>

                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </Modal.Body>
        {/* <Modal.Footer>
          {/* <button className='btn btn-tranparent border-primary text-primary'
            disabled={!selectedSegment}
            onClick={handleClose}>Close</button> */}
          {/* <button className='btn btn-primary'
            disabled={!selectedSegment}
            onClick={handleSave}>Ok</button>

        </Modal.Footer> */} 
      </Modal>
    </>
  );
};

export default AddGroupModel;
