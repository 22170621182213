// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-swatch-job-from{

    background-color: #fff;     border: 1px solid #cbd5e0; width: 97%; margin: 0 auto; padding:12px 12px 12px 12px; 

    border-radius: 8px;
}

.input-swatch{background-color: transparent!important;}`, "",{"version":3,"sources":["webpack://./src/module/projectPage/SelectSwatchJob.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB,MAAM,yBAAyB,EAAE,UAAU,EAAE,cAAc,EAAE,2BAA2B;;IAE9G,kBAAkB;AACtB;;AAEA,cAAc,uCAAuC,CAAC","sourcesContent":[".select-swatch-job-from{\n\n    background-color: #fff;     border: 1px solid #cbd5e0; width: 97%; margin: 0 auto; padding:12px 12px 12px 12px; \n\n    border-radius: 8px;\n}\n\n.input-swatch{background-color: transparent!important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
