import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addSwatchStyle, getMaterialStyle } from '../../../slice/materialSlice/MaterialStyleSlice'
import { getSwatchBrand } from '../../../slice/materialSlice/MaterialBrandSlice'
import { SwatchBrandModel } from '../../../Model/material/MaterialBrandModel'
import { MaterialStyleModel, SwatchStyleModel } from '../../../Model/material/MaterialStyleModel'

const GetSwatchStyle = () => {
  const getMaterialStyles = useSelector(getMaterialStyle) as MaterialStyleModel[]
  const getSwatchBrands = useSelector(getSwatchBrand) as SwatchBrandModel[]
  const dispatch = useDispatch()

  useEffect(() => {
    
    if (getSwatchBrands.length > 0 &&
       getMaterialStyles.length > 0) {
    
      const allStyle: SwatchStyleModel[] = getMaterialStyles.reduce((acc, item) => {
        const brand = getSwatchBrands.find(b => b.id === item.material_brand_id)
        if (brand) {
          acc.push({
            id: item.id,
            brand: brand,
            title: item.title,
            slug: item.slug,
            description: item.description,
            sort_order: item.sort_order,
            status: item.status,
          })
        }
        return acc
      }, [] as SwatchStyleModel[])

      if (allStyle.length > 0) {
        dispatch(addSwatchStyle(allStyle))
      }
    }
  }, [getSwatchBrands, getMaterialStyles, dispatch])

  return (
    <div></div>
  )
}

export default GetSwatchStyle
