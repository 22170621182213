import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobData, resetJobData, } from '../../../slice/userJobSlice/UserJobSlice'
import { JobSegmentationModel } from '../../../Model/masterArray/MasterArrayModel'

import { JobModel, JobSegmentModel, NewJobSegmentModel, Segment } from '../../../Model/Job/JobModel'
import { getNewSegment, getSegregateSegment, resetNewSegment, segegratedJobSegment } from '../../../slice/segegratedSlice/SegregatedSlice'
import { useUpdateEffect } from '@chakra-ui/react'

const SegegrateSegementBaseonPixel = () => {
  const dispatch = useDispatch()

  const getNewSegments= useSelector(getNewSegment)
const getUserJobDatas= useSelector(getUserJobData) as JobModel
 const isUpdate= useRef(true)
 const getSegregateSegments= useSelector(getSegregateSegment)

 useEffect(() => {
  if (getUserJobDatas && 
    getUserJobDatas.segments && isUpdate.current) {
    isUpdate.current=false
    const allSeg :JobSegmentModel= getUserJobDatas.segments||{};
    console.log("allSeg",allSeg)
    segregateSegments(allSeg)
  
    
    
  }
  else if(!getUserJobDatas) {
      isUpdate.current=true
  }
}, [getUserJobDatas,getSegregateSegments]);

// update the segment into segrated slice

useEffect(()=>{
  
  if(getNewSegments &&
    Object.keys(getNewSegments).length>0
  ){
   
  segregateSegments(getNewSegments)
  dispatch(resetNewSegment())
    console.log("getNewSegments",getNewSegments)
  }
},[getNewSegments])

//segrate the job
const segregateSegments=(allSeg:JobSegmentModel)=>{
  console.log("allSeg",allSeg)

  if (allSeg) {
    
    const segmentsArray = Object.keys(allSeg).map((key) => ({
       
      [key]: allSeg[key],
    }));
    
    
    const sortedSegments = segmentsArray.sort((a, b) => {
     
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];
    
    
      const perimeterA = a[keyA].details?.annotation_area_pixel || 0;
      const perimeterB = b[keyB].details?.annotation_area_pixel || 0;
    
  
      return perimeterB - perimeterA;
    });
    

    if (sortedSegments.length > 0) {
      // dispatch(startSegregation())
      dispatch(segegratedJobSegment(sortedSegments));
     
    }
  }

}


  return (
    <div></div>
  )
}

export default SegegrateSegementBaseonPixel