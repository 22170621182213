// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-button {
    padding: 10px;
    cursor: pointer;
  }
  
  .tab-button.active {
    background-color: lightblue;
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/module/ImageView/LeftSections/EachSegments/GroupSeg.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,2BAA2B;IAC3B,iBAAiB;EACnB","sourcesContent":[".tab-button {\n    padding: 10px;\n    cursor: pointer;\n  }\n  \n  .tab-button.active {\n    background-color: lightblue;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
