// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-img img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    object-fit: cover;
}

.create-project .seg-table-container {
    background-color: #fff;
    margin-top: 50px;
    border-radius: 5px;
    border: 1px solid #edf2f7;
}

.table-head th {
    padding-top: 26px !important;
    padding-bottom: 20px !important;
    font-family: "Poppins", sans-serif !important;
}

/* .table-container{background-color:#f9f9f9 ;} */
.action-icon-table {
    margin-right: 15px;
    cursor: pointer;
}

.trash-icon-media i {
    font-size: 15px !important;
    display: flex;
}

.create-pro-btn {
    display: flex;
    align-items: center;
}

.new-entry-btn {
    display: flex;
    justify-content: right;
    margin-top: 30px;
}

.create-new-entry-btn {
    background-color: #6d455a !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 4px 12px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
}

.brand-title {
    text-align: left;
}

.brand-right-section{margin: 0 auto;}`, "",{"version":3,"sources":["webpack://./src/module/brandPage/jobs/ShowBrands.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;IAC/B,6CAA6C;AACjD;;AAEA,iDAAiD;AACjD;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,kCAAkC;IAClC,eAAe;IACf,2BAA2B;IAC3B,4BAA4B;IAC5B,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB;;AAEA,qBAAqB,cAAc,CAAC","sourcesContent":[".media-img img {\n    width: 40px;\n    height: 40px;\n    border-radius: 50px;\n    cursor: pointer;\n    object-fit: cover;\n}\n\n.create-project .seg-table-container {\n    background-color: #fff;\n    margin-top: 50px;\n    border-radius: 5px;\n    border: 1px solid #edf2f7;\n}\n\n.table-head th {\n    padding-top: 26px !important;\n    padding-bottom: 20px !important;\n    font-family: \"Poppins\", sans-serif !important;\n}\n\n/* .table-container{background-color:#f9f9f9 ;} */\n.action-icon-table {\n    margin-right: 15px;\n    cursor: pointer;\n}\n\n.trash-icon-media i {\n    font-size: 15px !important;\n    display: flex;\n}\n\n.create-pro-btn {\n    display: flex;\n    align-items: center;\n}\n\n.new-entry-btn {\n    display: flex;\n    justify-content: right;\n    margin-top: 30px;\n}\n\n.create-new-entry-btn {\n    background-color: #6d455a !important;\n    color: #fff !important;\n    font-family: \"Poppins\", sans-serif;\n    font-size: 16px;\n    font-weight: 400 !important;\n    padding: 4px 12px !important;\n    border-radius: 4px !important;\n    display: flex;\n    align-items: center;\n    border: 1px solid transparent;\n}\n\n.brand-title {\n    text-align: left;\n}\n\n.brand-right-section{margin: 0 auto;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
