import { Card } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Container, Input, } from "@chakra-ui/react";
import "./CreateProject.scss"
import { CreateProjectModel } from '../../Model/project/ProjectModel';
import { useDispatch, useSelector } from 'react-redux';
import { addImageFile } from '../../slice/user/UserProject';
import Loading from '../loading/Loading';
import { getLoading, stopLoading } from '../../slice/loading/LoadingSlice';
import dotSvg from "../../../public/img/svgImage.svg"


import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { getUserLogin } from '../../slice/user/UserSlice';
import { addUploadMessage, getIsUploadStart, getUploadMessage, startUplaod } from '../../slice/modelData/ModelDataSlice';
import { Button } from 'react-bootstrap';
import "./Spinner.scss"
import WebSocketGenAi from '../ImageView/genAi/WebSocketGenAi';
import StartWebSocket from '../webSocket/StartWebSocket';
import { addMessage } from '../../slice/messageToast/ToastSlice';
type Props={
  callAPI:(data:CreateProjectModel)=>void
  resetCreateProject:()=> void
}

const CreateProject = ({callAPI, resetCreateProject}:Props) => {
 // To store the data URL of the thumbnail

 const getUserLogins= useSelector(getUserLogin)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const getIsUploadStarts=useSelector(getIsUploadStart)
  const getUploadMessages= useSelector(getUploadMessage)
   const[ uploadMessage, setUploadMessage]= useState<string|null>()
   const [isUploadStart, setIsUploadStart]= useState<boolean>(false)

   // update message and upload

useEffect(()=>{
  if(getUploadMessages!=""){
    setUploadMessage(getUploadMessages)
  }
},[getUploadMessages])


  // isUpload start
   useEffect(()=>{
    if(getIsUploadStarts){
      setIsUploadStart(getIsUploadStarts)
    }else{
      setIsUploadStart(false)
    }
   },[getIsUploadStarts])


  const [openDiv, setOpenDiv] = useState(true);
  const getLoadings= useSelector(getLoading)
   const [projectName, setProjectName]=useState<string>()
       const dispatch= useDispatch()
      const handleProjectName=(value:string)=>{
      setProjectName(value)
      
      }

  const handleDragImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [imageFile, setImageFile] = useState<File | null>(null); // State to store the selected file
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(""); // State to store the URL of the thumbnail


  const handleImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const ddff=event.target.files;
    if(ddff && ddff.length>0){
      const file = ddff[0]; // Get the selected file
      if (!file) return;
  
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image file and thumbnail URL
        
        setThumbnailUrl(reader.result as string); // Set the thumbnail URL
      };
      reader.readAsDataURL(file); 
      setImageFile(file); 
     
     
       
    }
   
  };


  useEffect(()=>{
    if(thumbnailUrl!=""){
     setOpenDiv(false)
    }else{
      setOpenDiv(true)
    }
   
  },[thumbnailUrl])
  const handleReset=()=>{
    dispatch(stopLoading())
   
     setImageFile(null)
     setThumbnailUrl("")
     setShow(false)
     resetCreateProject()
    //  setOpenDiv(false)
  }

  const handleClose=()=>{
    
    dispatch(stopLoading())
   
    setImageFile(null)
    setThumbnailUrl("")
    setShow(false)
    resetCreateProject()
    // setOpenDiv(false)
  }

  const handleProjectSubmit=()=>{
    console.log("projectName",projectName)
    console.log("imageFile",imageFile)
    if(projectName==undefined &&imageFile==null){
      dispatch(addMessage({
        isShow:true,
        mess:"Please fill respective field"
    }))
    }
    else if(projectName==undefined){
      dispatch(addMessage({
        isShow:true,
        mess:"Please fill project name"
    }))
    
     }
    else if(imageFile==null){
      dispatch(addMessage({
        isShow:true,
        mess:"Please select image"
    }))
    }
     else{
      const data={
        projectName:projectName,
        image:imageFile
      }
      dispatch(startUplaod(true))
  
      dispatch(addUploadMessage("Creating Project"))
     
      
      dispatch(addImageFile(imageFile))
       callAPI(data)
     }
    
     
  }


  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

  return (
    <div className='h-100' style={{marginTop:30}}>
      <section className='create-project h-100 d-flex align-items-center justify-content-center'>
        <div className='container'>
          <div className='row mt-4'>
            <div className='col-12 col-md-4 m-auto'>
              <h2 className='text-center mb-4'>Which option best describes you?</h2>
              <div className='d-flex flex-column gap-3'>
                <div className='card'>
                  <div role='button' className='card-body p-4' onClick={handleShow}>
                    <h5 className='m-0'>I'm a homeowner</h5>
                    <p className='m-0'>I'll be using DZINLY for projects on my property.</p>
                  </div>
                </div>

             
               <div className='card'>
                  <div role='button' className='card-body p-4' onClick={handleShow}>
                    <h5 className='m-0'>I'm a Realtor</h5>
                    <p className='m-0'>I'll be using DZINLY for projects on my property.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      <Offcanvas show={show} onHide={handleClose} placement="bottom" className=" bottom_side">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='container'>
            <div className='row'>


            {!isUploadStart &&
             <div className='col-12 col-md-6'>
                <h2 className='fw-semibold'>Add a photo</h2>
                <FormControl className='my-3'>
                  <FormLabel className='form-label fw-semibold'>Project Name</FormLabel>
                  <Input type="text" className='form-control' value={projectName} onChange={(e)=>handleProjectName(e.target.value)}/>
                </FormControl>
                {/* <h5>For best results, make sure your image follows these guidelines:</h5>
                <div className='guide-text'>
                  <ul>
                    <li>Minimum 1600px wide and landscape orientation</li>
                    <li>JPG, JPEG or PNG formats (Cannot accept blueprints in PDF format)</li>
                    <li>Maximum file size of 16 MB</li>
                    <li>Clear view of the subject with no obstructions</li>
                    <li>High-quality image with no blurriness</li>
                  </ul>
                </div> */}
                

                <div className='row'>
                  <div className='col-12 col-md-6'>
                  <h6>Segment
                              <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle className="spinner qM83" cx="4" cy="12" r="2" />
                                <circle className="spinner oXPr" cx="12" cy="12" r="2" />
                                <circle className="spinner ZTLf" cx="20" cy="12" r="2" />
                              </svg>
                            </h6>
                    <ul className='segmentlist'>
                      <li>Door <span>(2)</span></li>
                      <li>Window <span>(6)</span></li>
                      <li>Wall <span>(2)</span></li>
                      <li>Roof <span>(1)</span></li>
                      <li>Trim <span>(1)</span></li>
                      <li>Shutter <span>(2)</span></li>
                      <li>Gutters <span>(8)</span></li>
                      <li>Garage Door <span>(1)</span></li>
                      <li>Lights <span>(20)</span></li>
                      <li>Soffit <span>(0)</span></li>
                      <li>Railing <span>(1)</span></li>
                      <li>Floor <span>(0)</span></li>
                    </ul>
                  </div>
                  <div className='col-12 col-md-6'>
                    <h6>CATEGORIES 
                    <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <circle className="spinner qM83" cx="4" cy="12" r="2" />
                        <circle className="spinner oXPr" cx="12" cy="12" r="2" />
                        <circle className="spinner ZTLf" cx="20" cy="12" r="2" />
                    </svg>
                      </h6>
                    <ul className='segmentlist'>
                      <li>Wall Panels</li>
                      <li>Siding</li>
                      <li>Brick</li>
                      <li>Gutters</li>
                      <li>Pediment</li>
                      <li>Front Door</li>
                    </ul>
                  </div>
                </div>
              </div>}

              {isUploadStart &&
              <div className='col-12 col-md-6 '>
                
                <h4  className='d-flex flex-colume justify-content-start align-items-center h-100'>
                  {uploadMessage} 
                <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <circle className="spinner qM83" cx="4" cy="12" r="2" />
                        <circle className="spinner oXPr" cx="12" cy="12" r="2" />
                        <circle className="spinner ZTLf" cx="20" cy="12" r="2" />
                    </svg>
                   
                  
                   </h4>
                </div>
             

              }

              <div className='col-12 col-md-6'>
                <div className='card h-100'>
                  <div className='card-body upload-input d-flex flex-column align-items-center justify-content-center upload'>
                    <FormControl className="upload-button">
                      <Input
                        className="create-project-input"
                        type="file"
                        onChange={handleImage}
                        borderRadius="md"
                        borderColor="gray.400"
                        style={{ display: "none" }}
                        _focus={{ borderColor: "blue.500" }}
                      ref={fileInputRef}
                      />
                    </FormControl>

                    {openDiv && (
                      <div className="upload-img-button-box" onClick={handleDragImage}>
                        <div className="upload-btn text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z" />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                          </svg>
                          <p className='text-center'>
                            Click to upload or <br/>Darg and Drop house image here
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Display the thumbnail if it exists */}
                    {thumbnailUrl && (
                      <img
                        src={thumbnailUrl}
                        alt="Thumbnail"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                    )}
                  </div>
                </div>
                

              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className='d-flex align-items-center justify-content-center p-3 gap-3 border-top'>
            
            <button className="close-btn-handle-project rounded-pill btn btn-primary" 
            onClick={handleClose}>Close</button>

            <button className="reset-btn-handle-project rounded-pill btn btn-primary" onClick={handleReset}>Reset</button>

            <button className='rounded-pill btn btn-primary' onClick={handleProjectSubmit}  >Create Design</button>
             {/* <Link to="/generate-image"> <Button className='rounded-pill' onClick={handleProjectSubmit} >Create Design</Button></Link> */}
            {/* disabled={!imageFile || !thumbnailUrl} */}

        </div>
      </Offcanvas>

      {getLoadings && <Loading/>}

       {/*  start webSocket */}
       <StartWebSocket
       />
    </div>

  )
}

export default CreateProject
