import React, { useEffect, useRef, useState } from 'react'
import { ReplaceWindowModel, SegmentAnnotationModel, SegmentReplace } from '../../../../Model/replaceSwatchModel/ReplaceWindowSwatch'
import { ReplaceWindowDoorGarageSwatch } from '../api/SelectSwatchApi'
import { useDispatch, useSelector } from 'react-redux'
import { addSvgOpenVCImage } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { addSwatchOnCanvas } from '../../../../slice/canvas/masterArray/SwatchOnCanvas'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import { updateSwatchImage } from '../../../../slice/canvas/masterArray/MasterArraySlice'

type Props={
  
  palletUrl:string
  master_image_url:string
  allSegmentAnnotation:SegmentAnnotationModel[]
  classNames:SegmentReplace[]
  apiName:string
  selctedSwatch:SwatchesModel
  projectId:number
  groupName:string
  subGroupName:string
  resetWindowSwatch:(data:boolean)=>void

}
const WindowDoorGarageReplace = ({master_image_url,allSegmentAnnotation,palletUrl,
  classNames,apiName,selctedSwatch,resetWindowSwatch,projectId,groupName,subGroupName}:Props) => {

  const  dispatch= useDispatch()
  const isAPi = useRef(true)
  const[userId, setUserId]= useState<number|null>(null)
  const getUserLogins= useSelector(getUserLogin)

 
// set user Id
useEffect(()=>{
  if(getUserLogins && getUserLogins.length>0){
    const id= getUserLogins[0].id
    if(id){
      setUserId(id)
    }
    
  }
},[getUserLogins])
  useEffect(()=>{
   
    if(palletUrl &&
      master_image_url&&
      projectId &&
      userId &&
      allSegmentAnnotation&&
      allSegmentAnnotation.length>0  &&
      apiName&&
      groupName &&subGroupName&&
      selctedSwatch && 
      classNames  &&classNames.length>0 &&
      isAPi.current){
        isAPi.current= false
        windowReplaceSwatch()
    }
  },[palletUrl,master_image_url,
    allSegmentAnnotation,classNames,
    apiName,selctedSwatch,projectId,userId,
    groupName,subGroupName])

  const windowReplaceSwatch=async()=>{

    const data:ReplaceWindowModel={
      masterImage:master_image_url||"",
      swatchUrl:palletUrl||"",
      className:classNames||[],
      segmentationIntValue:allSegmentAnnotation||[],
      projectId:projectId,
      image_name:`${userId}_${groupName}_`
    }
    try{

      const response= await ReplaceWindowDoorGarageSwatch( data,apiName) 
     
      if(response && response.status==200 ){
        const value = response.data
        dispatch(addSvgOpenVCImage({
          SwatchImage:value
          
        }))
        const data={
          groupName:groupName,
          subGroupName:subGroupName,
          swatchImage:value,
          userId:userId,
          swatchId:selctedSwatch.id,
          swatchTitle:selctedSwatch.description,
          swatchPhoto:selctedSwatch.photo
        }

        const data2={
          swatchImages:value,
          swatchId:selctedSwatch.id,
          swatchTitle:selctedSwatch.description,
          swatchPhoto:selctedSwatch.photo
        }

          // add swatch image in master Array
          dispatch(updateSwatchImage(data))

         // swatch to SwatchonCanvas to swatch on canavas
         dispatch(addSwatchOnCanvas(data2))
        
      
      }
      isAPi.current= true
      resetWindowSwatch(true)
    }catch(err){
      isAPi.current= true
      resetWindowSwatch(false)
      console.log("Error on replacing window swatch")
    }
  }
  return (
    <div>
        
    </div>
  )
}

export default WindowDoorGarageReplace

