import React, { useEffect, useState } from 'react'
import ProjectComment from './ProjectComment'
import MainComments from './MainComments'
import { useDispatch, useSelector } from 'react-redux'
import { closeMainCanvas, getCavasGroupComments } from '../../../../slice/projectCommet/ProjectCommentSlice'
import UserIcon from './UserIcon'
import { getUserJobData } from '../../../../slice/userJobSlice/UserJobSlice'
import { useFBO } from '@react-three/drei'
import { MessageModel, ProjectCommentModel } from '../../../../Model/comments/CommentsModel'
import AddComments from './AddComments'

type Props={
  isChat:boolean
}
const CommentHome = () => {
   const getCavasGroupCommentss= useSelector(getCavasGroupComments)// get 
    const[isMAinComment, setIsMainCommemt]=useState<boolean>(false)
    const[isUsetIconComment, setIsUsetIconComment]=useState<boolean>(false)
    const[startUpload, setStartUpload]=useState<boolean>(false)
     const getUserJobDatas= useSelector(getUserJobData)
     const [addMessageValue, setAddMessageValue]= useState<ProjectCommentModel|null>(null)
     const [message, setMessage]= useState<string|null>(null)
     const [userId, setUserId]= useState<number|null>(null)
     const [userName, setUserName]= useState<string|null>(null)
      const dispatch= useDispatch()
     useEffect(()=>{
    
    if(getCavasGroupCommentss && 
      getCavasGroupCommentss.isMainComment){
      setIsMainCommemt(true)
      setIsUsetIconComment(false)
    } 
   
     if(getCavasGroupCommentss &&
       getCavasGroupCommentss.isUserIcon){
      console.log(" userIcon")
      setIsMainCommemt(false)
      setIsUsetIconComment(true)
    }
   },[getCavasGroupCommentss])

  //  user detials
  useEffect(()=>{
    if(getUserJobDatas &&
       getUserJobDatas.id &&
        getCavasGroupCommentss.isMainComment &&
        getCavasGroupCommentss.segName &&
        getCavasGroupCommentss.pointer &&
        getCavasGroupCommentss.pointer.x &&
        getCavasGroupCommentss.pointer.y &&
        userId &&
        message &&
        userName
      ){
        const date1= new Date()
     const data={
      jobId:getUserJobDatas.id ,
      status:"pending",
      isVisible:true,
      position:{
        x: getCavasGroupCommentss.pointer.x ,
        y: getCavasGroupCommentss.pointer.y
      },
      segment:getCavasGroupCommentss.segName,
      createdAt:date1,
      updatedAt: date1,
      replies:[{
        name:userName,
        userId:userId,
        message:message,
        createdAt:date1,
      }]

     }
     if(data)
     setAddMessageValue(data)
     setStartUpload(true)
     dispatch(closeMainCanvas())
    }
  },[getUserJobDatas,getCavasGroupCommentss, userId , message, userName])


  const handleAddMessage=(data:MessageModel)=>{
    if(data.message && data.userId && data.name){
      setUserId(data.userId)
      setMessage(data.message)
      setUserName(data.name)
     
    }
   
  }

  const handleResetComment=()=>{
    setAddMessageValue(null)
    setStartUpload(false)
    setUserId(null)
    setMessage(null)
  }
  return (
    < >
       
         {/* open main comment */}
       
        <MainComments
        addMessage={handleAddMessage}
        />

        {/* open user Icon  */}
       
       {/* <UserIcon/> */}

       {/* add message Api */}

      { startUpload &&
      addMessageValue &&
       <AddComments
       addMessageValue={addMessageValue}
       resetAddComment={handleResetComment}
       />}




    </>
  )
}

export default CommentHome