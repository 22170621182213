// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-image{text-align: center!important;}
.comapare-img{

    --divider-width: 3px!important;
    --default-handle-width: 80px;
}


.before,
  .after {
    margin: 0;
  }

  .before figcaption,
  .after figcaption {
    background: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    color: #1a202c;
    opacity: 0.8;
    padding: 12px 13px 9px 13px;
    position: absolute;
    top: 50%;
    font-size: 16px;
    font-weight: 400;
    transform: translateY(-50%);
    line-height: 100%;
    font-family: Poppins, sans-serif;
  }

  .before figcaption {
    left: 12px;
  }

  .after figcaption {
    right: 12px;
  }`, "",{"version":3,"sources":["webpack://./src/module/canavas/compare/Compare.css"],"names":[],"mappings":"AAAA,eAAe,4BAA4B,CAAC;AAC5C;;IAEI,8BAA8B;IAC9B,4BAA4B;AAChC;;;AAGA;;IAEI,SAAS;EACX;;EAEA;;IAEE,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,QAAQ;IACR,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,iBAAiB;IACjB,gCAAgC;EAClC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb","sourcesContent":[".compare-image{text-align: center!important;}\n.comapare-img{\n\n    --divider-width: 3px!important;\n    --default-handle-width: 80px;\n}\n\n\n.before,\n  .after {\n    margin: 0;\n  }\n\n  .before figcaption,\n  .after figcaption {\n    background: #fff;\n    border: 1px solid #c0c0c0;\n    border-radius: 6px;\n    color: #1a202c;\n    opacity: 0.8;\n    padding: 12px 13px 9px 13px;\n    position: absolute;\n    top: 50%;\n    font-size: 16px;\n    font-weight: 400;\n    transform: translateY(-50%);\n    line-height: 100%;\n    font-family: Poppins, sans-serif;\n  }\n\n  .before figcaption {\n    left: 12px;\n  }\n\n  .after figcaption {\n    right: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
