import React, { useEffect, useState } from 'react'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { useDispatch, useSelector } from 'react-redux'
import { addAllSwatchImages, getSelectedSegment } from '../../../../slice/userJobSlice/UserJobSlice'
import { getAllSwatches } from '../../../../slice/materialSlice/MaterialSlice'
import { useSelect } from '@react-three/drei'

const SegmentBaseSwatch = () => {

    const getAllSwatchess= useSelector(getAllSwatches)
    const dispatch= useDispatch()
    const getSelectedSegments= useSelector(getSelectedSegment)

    useEffect(()=>{
        
     if( getSelectedSegments &&
        getSelectedSegments.length>0 &&
          getAllSwatchess &&
          getAllSwatchess.length>0 
         ){
            
            const firstValue=getSelectedSegments[0].details?.seg_type;
           
            const masterSegName=firstValue
              console.log("masterSegName",masterSegName)
                if(masterSegName){
                    getAllSegmentbaseSwatch(masterSegName)
                }
            
           
         }
 },[getAllSwatchess,getSelectedSegments])
 
 const getAllSegmentbaseSwatch=(masterSegName:string)=>{

  if(masterSegName==="Window"){
    //const searchSeg=["Windows","Vents",]
    const searchSeg=["Windows",]
    getSwatch(searchSeg)
    // masterSegName="Windows"
}
else if (masterSegName==="Roof"){
    const searchSeg=["Roofing ", "Tile","Paint"]
    getSwatch(searchSeg)
}
else if (masterSegName==="Wall"){
   // const searchSeg=["Brick","Siding","Wall Panels","Pediment","EIFS","Stone","Stain"]
    const searchSeg=["Brick","Wall Panels","Pediment","Stone"]

    getSwatch(searchSeg)
}
else if (masterSegName==="Trim"){
    const searchSeg=["Paint", "Soffit"]
    getSwatch(searchSeg)
}
else if (masterSegName==="Door"){
    const searchSeg=["Doors"]
    getSwatch(searchSeg)
} else if (masterSegName==="GarageDoor"){
const searchSeg=["Garage Doors","Gate"]
getSwatch(searchSeg)
}
else if (masterSegName==="Gutter"){
const searchSeg=["Gutters"]
getSwatch(searchSeg)
}
 }
 
 const getSwatch=(searchSeg:string[])=>{
   const swat:SwatchesModel[]=[]
 
   searchSeg.map(items=>{
     const allSwatch:SwatchesModel[]= getAllSwatchess.filter(item=>
         item.style_brand_category?.brand?.category?.title===items
     )
     swat.push(...allSwatch)
   })
   
  
 if (swat.length > 0) {
 //   const sortedSwatches = swat.sort((a, b) => (b.is_featured ? 1 : -1));
 // setAllSwatchImage(sortedSwatches);
    dispatch(addAllSwatchImages(swat))
 }
 
 }
  return (
    <div>
        
    </div>
  )
}

export default SegmentBaseSwatch