import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addMaterialBrand, getMaterialBrand } from '../../../slice/materialSlice/MaterialBrandSlice'

import GetSwatchBrand from './GetSwatchBrand'
import { GetAllMaterialBrands } from '../../../api/material/brand/BrandApi'


const GetMaterialBrand = () => {
const dispatch= useDispatch()
   const getMaterialBrands= useSelector(getMaterialBrand)
    useEffect(()=>{
      if(getMaterialBrands && getMaterialBrands.length===0){
        getAllMaterials_Brand()
      }
       
    },[getMaterialBrands])



    const getAllMaterials_Brand= async()=>{
        try{
     
             const response= await GetAllMaterialBrands()

              if(response.status===200){
                dispatch(addMaterialBrand(response.data))
              }
        }catch(err){
           console.log("err in getting all material")
        }

    }
  return (
    <div>
   
    </div>
  )
}

export default GetMaterialBrand