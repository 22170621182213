import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CanvasControlModel, GroupMasterSegment, JobSegmentationModel, MasterModel, ReAnnotationModel } from "../../../Model/masterArray/MasterArrayModel";


interface canvasControlStates {
   
    canvasControl:CanvasControlModel
    isDeleteSegment:boolean
    isDelete:boolean
}

const initialState: canvasControlStates = {
    canvasControl:{},
    isDelete:false,
    isDeleteSegment:false
    

};


const CanvasControlSlice = createSlice({
    name: "canvasControl",
    initialState,
    reducers: {
        addCanavasControl:(state,action)=>{
            const {groupName,subGroupName,segShortName,segName}= action.payload
            
            state.canvasControl.groupName=groupName
            state.canvasControl.subGroupName= subGroupName
            state.canvasControl.segShortName= segShortName
            state.canvasControl.segName= segName
        },
         resetCanvasControl:(state)=>{

                state.canvasControl={}
        }, 
        
        openCloseDeleteSegment:(state,action)=>{
           state.isDelete= action.payload
        },
        startDeletingSegment:(state)=>{
            state.isDeleteSegment=true
        },
        stopDeletingSegment:(state)=>{
            state.isDeleteSegment= false
        }
       
    }
})

export const {addCanavasControl,resetCanvasControl,
    startDeletingSegment,stopDeletingSegment,openCloseDeleteSegment
}=CanvasControlSlice.actions;
 export const getCanvasControl=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.canvasControl
 export const getDeleteStatus=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isDeleteSegment
 export const getDeleteModalStatus=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isDelete
export default CanvasControlSlice.reducer