// AppRoutes.tsx
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../page/homePage/HomePage';
import MediaPage from '../page/media/MediaPage';
import NotFound from '../page/notfound/NotFound';
import Layout from '../layout/Layout';
import Profile from '../page/profilePage/Profile';
import BrandRoutes from './brandRoutes/BrandRoutes';
import ProjectRoutes from './projectRoutes/ProjectRoutes';
import LoginPage from '../page/login/LoginPage';
import store from "../store/Store"
import ImageView from '../page/ImageView/ImageView';
import JobRoutes from './jobRoutes/JobRoutes';
import CreateProject from '../page/createproject/CreateProject';
import AuthRoutes from './authRoutes/AuthRoutes';
import CustomerRoutes from './customerRoute/CustomerRoute';
import AdminRoutes from './adminRoutes/AdminRoutes';
import CreateProjectHomes from '../module/createproject/CreateProjectHomes';
import SegmentHome from '../page/segment/SegmentHome';
import SegmentsRoutes from './segmentRoutes/SegmentsRoutes';
import UsersRoutes from './userroutes/UsersRoutes';
import ShapeRoutes from './shapeRoutes/ShapeRoutes';
import CanvasRoutes from './canvasRoute/CanvasRoutes';
import Generate_Image from '../page/generate_Image/Generate_Image';
import Edit_Image from '../page/generate_Image_edit/Edit_Image';




import { ChakraProvider } from '@chakra-ui/react';

import MediaRoutes from './mediaRoutes/MediaRoutes';
import DemoRoutes from './demoTable/DemoTable';
import Canvasview from '../page/canvas/canvasview';
import DasboardRoute from './dasboardRoute/DasboardRoute';
import SamModelPage from '../page/samModel/SamModelPage';



const AppRoutes: React.FC = () => {

  const imageUrl="https://betadzinly.s3.us-east-2.amazonaws.com/projects/355/image_355_original.jpg"
  return (
    <Provider store={store}>
    {/* <ChakraProvider> */}
      <Router>
        <Routes> 
          <Route path="/" element={<Layout />}>
            <Route index  element={<HomePage />} />
            <Route path ="auth/*" element={<AuthRoutes/>}/>
            <Route path="media/*" element={<MediaRoutes />} />
            <Route path="*" element={<NotFound />} />
            <Route path="profile" element={<Profile />} />
            <Route path="project/*" element={<CanvasRoutes />} />
            
            <Route path="brand/*" element={<BrandRoutes/>}/>
         
            <Route path="dashboard/*" element={<AdminRoutes/>}/>
         
            <Route path="projects/*" element={<ProjectRoutes/>}/>
                {/* customer route */}
            <Route path="customer/*" element={<CustomerRoutes/>}/>
            <Route path="createproject" element={<CreateProjectHomes/>}/>



            <Route path="segments/*" element={<SegmentsRoutes/>}/>
            <Route path="user/*" element={<UsersRoutes/>}/>
            <Route path="shape/*" element={<ShapeRoutes/>}/>
            <Route path="dashboard-page*" element={<DasboardRoute/>}/>
            


            <Route path="generate-image" element={<Generate_Image/>}/>
            <Route path="generate-image/edit" element={<Edit_Image/>}/>


            {/* <Route path="canvasview" element={<Canvasview     
                
            />}/> */}



            {/* <Route path="job/*" element={<JobRoutes/>}/> */}

            {/* demo table routes */}
            <Route path="demo-table/*" element={<DemoRoutes/>}/>
            <Route path="sam-model" element={<SamModelPage/>}/>

            {/* html canvas */}
           


          </Route>

        </Routes>
      </Router>
      {/* </ChakraProvider> */}
    </Provider>
  );
};

export default AppRoutes;
