
import axios from "axios";
import { ProjectCommentModel } from "../../Model/comments/CommentsModel";
const backendApi= process.env.REACT_APP_SERVER_URL

// Add user commnets
export function AddUserComments(addMessageValue:ProjectCommentModel){
    const data={
        addData: addMessageValue
      }
    return axios.post(`${backendApi}/add/project-comment`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}

// get all commentys based on jobid
export function GetUserComments(jobId:number){
    const data={
        jobId: jobId
      }
    return axios.post(`${backendApi}/get/project-comment`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}