import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getUserLogin, resetUser } from "../../slice/user/UserSlice";
import { userModel } from "../../Model/user/UserModel";
import { resetAllSwatchImages, resetBase64, resetSelectedSegment, resetUserJob } from "../../slice/userJobSlice/UserJobSlice";
import { resetAllProjectSlice } from "../../slice/user/UserProject";

import "./header.scss";
import { resetIsRegenaration, resetUploadMessage } from "../../slice/modelData/ModelDataSlice";

import { resetSvgImage } from "../../slice/svdImageSlice/SvgImageSlice";
import { resetCanvasControl } from "../../slice/canvas/masterArray/CanvasControlSlice";
import { startWebSocket } from "../../slice/webSocketSlice/WebSocketSlice";
import { resetPrompValue } from "../../slice/genAi/GenAiAttributesSlice";
import { stopLoading } from "../../slice/loading/LoadingSlice";
import { resetReplaceSwatch } from "../../slice/replaceSwatch/ReplaceSwatchSlice";
import { resetSegregated } from "../../slice/segegratedSlice/SegregatedSlice";
import { resetMasterArray } from "../../slice/canvas/masterArray/MasterArraySlice";
import { addMessage } from "../../slice/messageToast/ToastSlice";
import ThemeToggle from "../../components/ThemeToggle";

const AssetImage =process.env.REACT_APP_ASSERT_Image


const Header = () => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const getUserLogins = useSelector(getUserLogin) as userModel[];
  const [userName, setUserName] = useState<string>();

  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      if (getUserLogins[0] && getUserLogins[0].name) {
        setIsLogin(true);
        setUserName(getUserLogins[0].name);
      }
    } else {
      setIsLogin(false);
    }
  }, [getUserLogins]);

  const handleSignout = () => {
    dispatch(resetUserJob());
    dispatch(resetUser());
    dispatch(resetAllProjectSlice());
    removeProjectFromLocaStorage()
    navigator("/");
    dispatch(resetUser());
   dispatch(resetCanvasControl())
    //remove userproject from local storage
    localStorage.removeItem("getUserProjects")

  };

  const handleProfile = () => {
    const role = getUserLogins[0]?.role;
    
    dispatch(resetUserJob());
    removeCurrentProject()
    dispatch(stopLoading())
    navigator(`/${role}/project`);
  };

  const removeCurrentProject=()=>{
    localStorage.removeItem("getSegregateSegments") // remove segerated data
    localStorage.removeItem("getUserJobBase64s") // remove base64 image
    localStorage.removeItem("getMasterArrays") // remove masterArray
    localStorage.removeItem("getUserJobDatas")
    localStorage.removeItem("getMasterArrays")
    dispatch(resetSegregated())
    dispatch(resetUploadMessage())
    dispatch(resetIsRegenaration())
    dispatch(resetAllSwatchImages())  // delete all Swatch Image
    //dispatch(resetCanvasControl())
    dispatch(resetBase64()) // deleet base 64
    dispatch(resetSelectedSegment()) // reset the selected segments
    dispatch(resetPrompValue())// delete all prompts
  }

  const removeProjectFromLocaStorage=()=>{
    dispatch(resetSegregated())
    dispatch(resetSvgImage())
    removeCurrentProject()
    localStorage.removeItem("getUserJobDatas")
    localStorage.removeItem("getMasterArrays")
    dispatch(resetUploadMessage())
    dispatch(resetIsRegenaration())
    dispatch(resetBase64()) // deleet base 64
    dispatch(resetSelectedSegment())
    dispatch(resetAllSwatchImages())  // delete all Swatch Image 
  }
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logoUrl = "https://betadzinly.s3.us-east-2.amazonaws.com/assets/images/Logo.svg";
  const userimg = "https://betadzinly.s3.us-east-2.amazonaws.com/assets/images/user2.jpg";


  const handleCreateProject=()=>{
    if(isLogin){
      dispatch(resetMasterArray())
      dispatch(resetSegregated())
      localStorage.removeItem("getSegregateSegments")
      localStorage.removeItem("getMasterArrays")
      navigator("/createproject")
       dispatch(startWebSocket())
    }else{
      dispatch(addMessage({
        isShow:true,
     mess:"Please login to continue."
      }))
    }
   
  }
  return (

  <nav className={`navbar navbar-expand-lg fixed-top ${scrolled ? 'header-active' : ''}`}>
    <div className="container">
      <Link to="/" className="navbar-brand"><img width="48" className="logo-img" src={`${AssetImage}/images/logo-icon.svg`} alt="Logo" /></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link to="/" className="nav-link active">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">Try It Out</Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">Pricing</Link>
          </li>
        </ul>
        {/* <ThemeToggle /> */}
        <div className="d-flex align-items-center gap-3">
          <button  onClick={handleCreateProject}
            role="button" className="btn btn-primary rounded-pill">Create Project</button>
          
          { !isLogin &&
            <Link to="/auth/login" role="button" className="btn btn-outline-dark rounded-pill">Sign In</Link>
          }

          {isLogin && 
            <Dropdown>
              <Dropdown.Toggle variant="light" className="bg-transparent border-0 p-0 header-user d-flex align-items-center gap-1 shadow-none" id="dropdown-basic">
                <span className="user">
                  <img src={`${AssetImage}/images/user2.jpg`} alt="userimg" /> 
                </span> 
                {userName}
              </Dropdown.Toggle>


              <Dropdown.Menu align="end">
                <Dropdown.Item as={Link} to="/dashboard-page">Dashboard</Dropdown.Item>
                <Dropdown.Item as={Link} to="/dashboard">Dashboard 2</Dropdown.Item>
                <Dropdown.Item  onClick={handleProfile}>Profile</Dropdown.Item>
                <Dropdown.Item as={Link} to="/brand">Settings</Dropdown.Item>
                <Dropdown.Item as={Link} to="/" onClick={handleSignout}>Sign Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }

        </div>
       
      </div>
      </div>
    </nav>
  );
};

export default Header;