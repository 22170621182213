
// import { onnxMaskToImage, clearMask } from "./MaskUtil";
// import Npyjs from 'npyjs';

// import * as ort from 'onnxruntime-web'; // Importing the 'ort' namespace

// let [distanceHeight, distanceWidth] = distanceSize();
// let lefts: Array<{ x: number; y: number; clickType: number }> = [];
// let rights: Array<{ x: number; y: number; clickType: number }> = [];
// let model: ort.InferenceSession | null = null;
// let tensor: ort.Tensor | null = null;
// let drawMode: number = 0;






// export const samInit = async () => {
//     try {
//         // Load the ONNX model
//         const MODEL_DIR = '/decoder/sam_onnx_quantized.onnx';
//         model = await ort.InferenceSession.create(MODEL_DIR);
//         console.log('Model loaded');

//         // Load the .npy file for image embedding using npyjs
//         const IMAGE_EMBEDDING = '/embedding.npy';
//         const npyjs = new Npyjs();
//         const response = await fetch(IMAGE_EMBEDDING);
//         const arrayBuffer = await response.arrayBuffer();

//         // Parse the .npy file and create a tensor
//         const npyFile = await npyjs.load(arrayBuffer);
//         tensor = new ort.Tensor('float32', npyFile.data as Float32Array, npyFile.shape);
//         console.log('Image embedding loaded');

//         drawMode = 0;
//     } catch (error) {
//         console.error('Error initializing SAM:', error);
//     }
// };



// export const mouseEvents = () => {
//     document.body.addEventListener("mouseup", async (e) => {
//         if (drawMode === 0) return;
//         if (drawMode === 1) drawMode = 2;
//         let X = e.pageX - distanceWidth;
//         let Y = e.pageY - distanceHeight;
//         mouseClick(e, [X, Y]);
//     });

//     document.body.addEventListener("mousemove", (e) => {
//         if (drawMode !== 1) return;
//         let X = e.pageX - distanceWidth;
//         let Y = e.pageY - distanceHeight;
//         hoverMouseMove([X, Y]);
//     });

//     document.body.addEventListener("keydown", (e) => {
//         if (e.code === "Space") {
//             let contours = findContour();
//             for (let i = 0; i < contours.length; i++) {
//                 let info = {
//                     points: [contours[i]].toString(),
//                     fill: "#aa000f",
//                 };
//                // drawPolygon(info);
//             }
//             document.querySelectorAll("circle").forEach(circle => circle.remove());
//         }
//         lefts = [];
//         rights = [];
//         drawMode = 1;
//     });
// };

// declare const cv: any; // Add this globally to avoid TypeScript errors

// const findContour = (smallRegionThreshold = 50): Uint32Array[] => {
//     const maskImg = document.getElementById('maskImg') as HTMLCanvasElement;
//     if (!maskImg) {
//         console.error('No mask image found');
//         return [];
//     }

//     const src = cv.imread(maskImg); // Read the canvas image
//     cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0); // Convert to grayscale
    
//     const contours = new cv.MatVector(); // MatVector to hold contours
//     const hierarchy = new cv.Mat(); // Hierarchy Mat
    
//     // Find contours
//     cv.findContours(src, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
    
//     const validContours: Uint32Array[] = [];
//     for (let i = 0; i < contours.size(); i++) {
//         const contour = contours.get(i); // Get individual contour
//         const area = cv.contourArea(contour); // Calculate contour area
//         if (area > smallRegionThreshold) {
//             validContours.push(new Uint32Array(contour.data32S)); // Store valid contour
//         }
//     }

//     // Clean up memory
//     src.delete();
//     hierarchy.delete();
    
//     return validContours;
// };


// export const hoverChange = (hover: boolean) => {
//     drawMode = hover ? 1 : 0;
//     if (!hover) clearMask();
// };

// const mouseClick = async (e: MouseEvent, coor: number[]) => {
//     let fill = e.button === 0 ? "#ff0000" : "#0000ff";
//     const clickType = e.button === 0 ? 1 : 0;

//     if (e.button === 0) {
//         lefts.push({ x: coor[0], y: coor[1], clickType });
//     } else {
//         rights.push({ x: coor[0], y: coor[1], clickType });
//     }

//     let clicks = lefts.concat(rights);
//     await run(clicks);

//     let info = {
//         name: "dot",
//         id: "dot",
//         x: coor[0],
//         y: coor[1],
//         fill,
//         r: "4"
//     };
//     //drawCircle(info);
// };

// const hoverMouseMove = async (coor: number[]) => {
//     if (drawMode !== 1) return;
//     let click = {
//         x: coor[0],
//         y: coor[1],
//         clickType: 1,
//     };
//     let clicks = [click];
//     await run(clicks);
// };

// const run = async (clicks: any[]) => {
//     const scaleResult = handleImageScale();
    
//     if (!scaleResult) {
//         console.log("Failed to get image scale, image may not be loaded yet.");
//         return;
//     }
//     const { height, width, samScale } = scaleResult;
//     let modelScale = {
//         samScale,
//         height,
//         width,
//     };
//     const feeds = modelData({
//         clicks,
//         tensor,
//         modelScale,
//     });
//     if (feeds === undefined) return;

//     const results = await model?.run(feeds);
//     if (results && model && model.outputNames) {
//         const output = results[model?.outputNames[0]];
//         if (output) {
//             const dataArray = output.data;
            
//             if (Array.isArray(dataArray)) {
//                 const numberArray = dataArray.map((value) => Number(value)); // Convert to numbers if needed
//                 onnxMaskToImage(numberArray, output.dims[2], output.dims[3]);
//             } else {
//                 onnxMaskToImage(Array.from(output.data as Iterable<number>), output.dims[2], output.dims[3]);

//             }
//         }
//     }
// };


// const handleImageScale = (): { height: number; width: number; samScale: number } | null => {
//     const LONG_SIDE_LENGTH = 1024;
//     const imgElement = document.getElementById("img") as HTMLImageElement;

//     if (!imgElement || !imgElement.complete) {
//         console.log("Image not loaded or missing");
//         return null;
//     }

//     const w = imgElement.naturalWidth;
//     const h = imgElement.naturalHeight;

//     if (w && h) {
//         const samScale = LONG_SIDE_LENGTH / Math.max(h, w);
//         const scaledWidth = w * samScale;
//         const scaledHeight = h * samScale;

//         return { height: scaledHeight, width: scaledWidth, samScale };
//     }

//     return null;
// };


// const modelData = ({ clicks, tensor, modelScale }: any) => {
//     const imageEmbedding = tensor;
//     let pointCoords;
//     let pointLabels;
//     let pointCoordsTensor;
//     let pointLabelsTensor;

//     if (clicks) {
//         let n = clicks.length;

//         pointCoords = new Float32Array(2 * (n + 1));
//         pointLabels = new Float32Array(n + 1);

//         for (let i = 0; i < n; i++) {
//             pointCoords[2 * i] = clicks[i].x * modelScale.samScale;
//             pointCoords[2 * i + 1] = clicks[i].y * modelScale.samScale;
//             pointLabels[i] = clicks[i].clickType;
//         }

//         pointCoords[2 * n] = 0.0;
//         pointCoords[2 * n + 1] = 0.0;
//         pointLabels[n] = -1.0;

//         pointCoordsTensor = new ort.Tensor("float32", pointCoords, [1, n + 1, 2]);
//         pointLabelsTensor = new ort.Tensor("float32", pointLabels, [1, n + 1]);
//     }
//     const imageSizeTensor = new ort.Tensor("float32", [
//         modelScale.height,
//         modelScale.width,
//     ]);

//     if (pointCoordsTensor === undefined || pointLabelsTensor === undefined)
//         return;

//     const maskInput = new ort.Tensor(
//         "float32",
//         new Float32Array(256 * 256),
//         [1, 1, 256, 256]
//     );
//     const hasMaskInput = new ort.Tensor("float32", [0]);

//     return {
//         image_embeddings: imageEmbedding,
//         point_coords: pointCoordsTensor,
//         point_labels: pointLabelsTensor,
//         mask_input: maskInput,
//         has_mask_input: hasMaskInput,
//         orig_im_size: imageSizeTensor,
//     };
// };


// const changeImageEvent = async (imageFile: File) => {
//     let formData = new FormData();
//     formData.append("file", imageFile, "image.jpg");
//     await fetch("http://localhost:8888/image-embedding", {
//         method: 'POST',
//         body: formData,
//         headers: {
//             accept: "application/json",
//         }
//     })
//         .then((response) => response.json())
//         .then((data) => {
//             let image_embedding = data.image_embedding;
//             const binaryString = atob(image_embedding);
//             const dataView = new DataView(new ArrayBuffer(binaryString.length));
//             for (let i = 0; i < binaryString.length; i++) {
//                 dataView.setUint8(i, binaryString.charCodeAt(i));
//             }
//             const float32Array = new Float32Array(dataView.buffer);
//             tensor = new ort.Tensor("float32", float32Array, data.image_embedding_shape);
//         })
//         .catch(() => {
//             alert('The image is not usable.');
//         });
// };