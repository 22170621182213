import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  getSelectedSegment } from '../../../../slice/userJobSlice/UserJobSlice'
import { alphaValueModel, SegmentAnnotationModel, SegmentReplace } from '../../../../Model/replaceSwatchModel/ReplaceWindowSwatch'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import WindowReplace from './WindowDoorGarageReplace'
import WallReplace from './WallRoofReplace'
import TrimReplace from './TrimReplace'

import WindowDoorGarageReplace from './WindowDoorGarageReplace'
import RoofReplace from './RoofReplace'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { getSegregateSegment } from '../../../../slice/segegratedSlice/SegregatedSlice'
import { addAllAnotation, addImageProjectId, addReplaceSwatch, getReplaceSwatch, resetReplaceSwatch } from '../../../../slice/replaceSwatch/ReplaceSwatchSlice'
import WallRoofReplace from './WallRoofReplace'

const urlpath = process.env.REACT_APP_Project_Image

  type Props={
    palletUrl:string
    selctedSwatch:SwatchesModel
    resetSwatch:(data:boolean)=>void
  }

const ReplaceSwatchHome = ({palletUrl,selctedSwatch,resetSwatch}:Props) => {
  const getSegregateSegments= useSelector(getSegregateSegment)
  const [allSegmentAnnotation, setAllSegmentAnnotation]= useState<SegmentAnnotationModel[]>([])
  const [selectedSegmentAnnotation, setSelectedSegmentAnnotation]= useState<SegmentAnnotationModel[]>([])
  const [bb_dim_pixel, setBb_dim_pixel]= useState<SegmentAnnotationModel[]>([])
  const getMasterArrays= useSelector(getMasterArray)
  const [master_image_url, setMaster_image_url]= useState<string|null>(null)
  const getSelectedSegments= useSelector(getSelectedSegment)
 
  const [classNames, setClassNames]= useState<SegmentReplace[]>([])
 
 
  const[ isApi, setIsApi]= useState<boolean>(false)
      // selected segement
   const getReplaceSwatchs= useSelector(getReplaceSwatch)
      const [projectId, setProjectId]= useState<number|null>(null)
      const isSwatchInfo= useRef(true)
     const dispatch= useDispatch()
      useEffect(()=>{
        
         if(getSelectedSegments &&
           getSelectedSegments.length>0 &&
    
           palletUrl &&
           isSwatchInfo.current
          ){
            isSwatchInfo.current= false
            getSwatchInfo()
          }
     
      },[getSelectedSegments ])


      const getSwatchInfo=()=>{
        const className:SegmentReplace[]=[]
        const alpha:alphaValueModel[]=[]
        const allSegInt:SegmentAnnotationModel[]=[]
        const bbox_dimension_pxl:SegmentAnnotationModel[]=[]
        let groupname: string | undefined;
        let apiName: string | undefined;
        let segType: string | undefined;
        let subgroupname: string | undefined;
        let height:number|undefined
        let width:number|undefined
        if(getSelectedSegments &&
           getSelectedSegments.length>0){
              segType=getSelectedSegments[0]?.details?.seg_type
             const name=segType?.toLowerCase()
             apiName=name
            // setSegmentType(segName??"")
            // setApiName(name??"")
             
             getSelectedSegments.map(item=>{
              if(item && 
                item.details?.label&& 
                item.details && 
                item.details.bb_area_pixel &&
                item.details.bb_dimension_pixel &&
                 item.details.annotation
              ){
                groupname=item.details.seg_type??""
                subgroupname=item.details.group??""
                const bb_dim_pixel=item.details.bb_dimension_pixel
                   if(bb_dim_pixel && bb_dim_pixel.length>0){
                    height=parseInt(bb_dim_pixel[0])
                    width=parseInt(bb_dim_pixel[1])
                   }
                  
                  const classdata={
                    [item.details?.label]:item.details?.bb_area_pixel
                   }
                   const data1={
                    [item.details?.label]:item.details?.annotation
                   }
                   const bb_dim={
                    [item.details?.label]:[height??1, width??1]
                   }

                   const segAlpha={
                    [item.details?.label]:0.5
                   }

                   alpha.push(segAlpha)
                   allSegInt.push(data1)
                   className.push(classdata)
                   bbox_dimension_pxl.push(bb_dim)
                }
                  
             })
        }
        if(className.length>0 &&
          allSegInt.length>0 &&
          bbox_dimension_pxl.length>0 &&
          groupname &&
          subgroupname &&
          segType &&
          alpha.length>0
        ){
         const newClassName= className.reduce((acc, curr) => ({ ...acc, ...curr }), {})
         const newallSegInt= allSegInt.reduce((acc, curr) => ({ ...acc, ...curr }), {}) 
         const newbbox_dimension_pxl= bbox_dimension_pxl.reduce((acc, curr) => ({ ...acc, ...curr }), {})
           dispatch(addReplaceSwatch({
             groupName:groupname,
             subGroupName:subgroupname ,
             segmentType:segType,
             className:[newClassName],
             bbox_dimension_pxl:[newbbox_dimension_pxl],
             segmentationIntValue:[newallSegInt],
             alpha_value:alpha,
             apiName:apiName
           }))
          
        //  setClassNames([newClassName])
        }
        // if(allSegInt.length>0){
         
        //   setSelectedSegmentAnnotation([newallSegInt])
        // }
        // if(bbox_dimension_pxl.length>0){
         
        //   setBb_dim_pixel([newbbox_dimension_pxl])
        // }
        // if(groupname && subgroupname){
        //   setGroupName(groupname)
        //   setSubGroupName(subgroupname)
        // }
        // if(alpha && alpha.length>0){
        //   setAlphaValue(alpha)
        // }
        
      }
     // getAll segment int value
     useEffect(()=>{
        
      const allSegInt:SegmentAnnotationModel[]=[]
      if(getSegregateSegments && getSegregateSegments.length>0){
        getSegregateSegments.map(item=>{
          Object.keys(item).forEach(seg=>{
            const value= item[seg]
            if(value && value.details &&
               value.details.label && 
               value.details.annotation){
                const data={
                  [value.details?.label]:value.details?.annotation
                }
                allSegInt.push(data)
               }
           
          })
        })
      }
      if(allSegInt.length>0){
        const newallSegInt= allSegInt.reduce((acc, curr) => ({ ...acc, ...curr }), {})
       dispatch(addAllAnotation({
        allAnnotation:[newallSegInt]
       }))
        setAllSegmentAnnotation([newallSegInt])
      }
  }        
  ,[getSegregateSegments])


  // get master image url
  useEffect(()=>{

    if(getMasterArrays && getMasterArrays.image &&
      getMasterArrays.project_id ){
        const projectId=getMasterArrays.project_id
        const urls=getMasterArrays.image
      const imageUrlWithPath = `${urlpath}/${projectId}/${urls}`
      setMaster_image_url(imageUrlWithPath)
      setProjectId(projectId)
      dispatch(addImageProjectId({
        projectId:projectId,
        image:imageUrlWithPath
      }))
    }
  },[getMasterArrays])


  /// calling Wall Replace 
     useEffect(()=>{
      if(getReplaceSwatchs &&
        getReplaceSwatchs.segmentType &&
        getReplaceSwatchs.groupName &&
        getReplaceSwatchs.subGroupName &&
        getReplaceSwatchs.apiName&&
        getReplaceSwatchs.bbox_dimension_pxl &&
        getReplaceSwatchs.className &&
        getReplaceSwatchs.apiName &&
        getReplaceSwatchs.allSegmentAnnotation&&
        getReplaceSwatchs.masterImage &&
        getReplaceSwatchs.projectId&&
        selctedSwatch 
        
      ){
        console.log("Api called")
        setIsApi(true)
      }else{
        console.log("wall api is  not called")
            console.log("segmentType",getReplaceSwatchs.segmentType)
            console.log("selctedSwatch",selctedSwatch)
            console.log("master_image_url",getReplaceSwatchs.masterImage)
            console.log("palletUrl",palletUrl)
            console.log("classNames",getReplaceSwatchs.className)
            console.log("subGroupName",getReplaceSwatchs.subGroupName)
            console.log("groupName",getReplaceSwatchs.groupName)
            console.log("bb_dim_pixel",getReplaceSwatchs.bbox_dimension_pxl)
            console.log("allSegmentAnnotation",getReplaceSwatchs.allSegmentAnnotation)
            console.log("projectId",getReplaceSwatchs.projectId)
      }
     },[getReplaceSwatchs,selctedSwatch])

  // useEffect(()=>{
   
  //   if(classNames!=null &&
  //     subGroupName!=null && 
  //     groupName!=null &&
  //     segmentType!=null &&
  //     selctedSwatch &&
  //     master_image_url!=null &&
  //     palletUrl &&
  //     allSegmentAnnotation &&allSegmentAnnotation.length>0 &&
  //     bb_dim_pixel &&bb_dim_pixel.length>0){
  //       console.log("wall api is called")
  //       setIsWallApi(true)
  //   }else{
  //     console.log("wall api is  not called")
  //     console.log("segmentType",segmentType)
  //     console.log("selctedSwatch",selctedSwatch)
  //     console.log("master_image_url",master_image_url)
  //     console.log("palletUrl",palletUrl)
  //     console.log("classNames",classNames)
  //     console.log("subGroupName",subGroupName)
  //     console.log("groupName",groupName)
  //     console.log("bb_dim_pixel",bb_dim_pixel)
  //     console.log("allSegmentAnnotation",allSegmentAnnotation)
  //   }
  // },[classNames,subGroupName,groupName,bb_dim_pixel,selctedSwatch,master_image_url,palletUrl,allSegmentAnnotation])

  const handleResetswatch=(data:boolean)=>{
   
    setIsApi(false) 
    isSwatchInfo.current= true
    dispatch(resetReplaceSwatch())
    resetSwatch(data)
  }
  const handleResetswatch1=(data:boolean)=>{
    setIsApi(false)
    dispatch(resetReplaceSwatch())
    isSwatchInfo.current= true
    resetSwatch(data)
    
  }
  return (
    <div>
        {/* replace window */}
        {isApi &&
        getReplaceSwatchs&&
        ( getReplaceSwatchs.segmentType=="Window" || getReplaceSwatchs.segmentType=="Door"|| getReplaceSwatchs.segmentType=="Garage") &&
        master_image_url!=null &&
        getReplaceSwatchs.apiName &&
        getReplaceSwatchs.groupName &&
        getReplaceSwatchs.subGroupName&&
        getReplaceSwatchs.segmentationIntValue &&
        getReplaceSwatchs.className &&
        getReplaceSwatchs.masterImage &&
        getReplaceSwatchs.projectId&&
          <WindowDoorGarageReplace
          projectId={getReplaceSwatchs.projectId}
          selctedSwatch={selctedSwatch}
          master_image_url={ getReplaceSwatchs.masterImage}
          palletUrl={palletUrl}
          allSegmentAnnotation={getReplaceSwatchs.segmentationIntValue}
          classNames={getReplaceSwatchs.className}
          apiName={getReplaceSwatchs.apiName}
          groupName={getReplaceSwatchs.groupName}
          subGroupName={getReplaceSwatchs.subGroupName}
          resetWindowSwatch={handleResetswatch1}
          />
        }
  
  {/*     replace wall  and Roof*/}
        {isApi &&
        getReplaceSwatchs &&
        ( getReplaceSwatchs.segmentType=="Wall" || getReplaceSwatchs.segmentType=="Roof") &&
        getReplaceSwatchs.groupName &&
        getReplaceSwatchs.subGroupName&&
        getReplaceSwatchs.className &&
        getReplaceSwatchs.bbox_dimension_pxl &&
        getReplaceSwatchs.apiName &&
        selctedSwatch &&
        getReplaceSwatchs.masterImage &&
        getReplaceSwatchs.projectId &&
          <WallRoofReplace
          projectId={getReplaceSwatchs.projectId}
          master_image_url={getReplaceSwatchs.masterImage}
          selctedSwatch={selctedSwatch}
          apiName={getReplaceSwatchs.apiName}
          palletUrl={palletUrl}
          allSegmentAnnotation={allSegmentAnnotation}
          classNames={getReplaceSwatchs.className}
          groupName={getReplaceSwatchs.groupName}
          subGroupName={getReplaceSwatchs.subGroupName}
          bbox_dimension_pxl={getReplaceSwatchs.bbox_dimension_pxl}
          resetWallSwatch={handleResetswatch}
          />
        }

  {/*     replace wall */}
        {/* {isApi &&
        getReplaceSwatchs.segmentType=="Roof" &&
       
        selctedSwatch &&
        getReplaceSwatchs.groupName &&
        getReplaceSwatchs.subGroupName&&
        getReplaceSwatchs.className &&
        getReplaceSwatchs.bbox_dimension_pxl &&
        getReplaceSwatchs.alpha_value &&
        getReplaceSwatchs.masterImage &&
        getReplaceSwatchs.projectId &&
          <RoofReplace
         
          selctedSwatch={selctedSwatch}
          projectId={getReplaceSwatchs.projectId}
          master_image_url={getReplaceSwatchs.masterImage}
          palletUrl={palletUrl}
          classNames={getReplaceSwatchs.className}
          groupName={getReplaceSwatchs.groupName}
          subGroupName={getReplaceSwatchs.subGroupName}
          allSegmentAnnotation={allSegmentAnnotation}
          alphaValue={getReplaceSwatchs.alpha_value}
          resetRoofSwatch={handleResetswatch}
          />
        } */}


  {/*     replace Trim */}
        {isApi &&
        getReplaceSwatchs.segmentType=="Trim" &&
      
        selctedSwatch&&
        getReplaceSwatchs.masterImage &&
        getReplaceSwatchs.projectId &&
        getReplaceSwatchs.groupName &&
        getReplaceSwatchs.subGroupName&&
        getReplaceSwatchs.className &&
        getReplaceSwatchs.bbox_dimension_pxl &&
        getReplaceSwatchs.segmentationIntValue &&
          <TrimReplace
          projectId={getReplaceSwatchs.projectId}
          master_image_url={getReplaceSwatchs.masterImage}
          classNames={getReplaceSwatchs.className}
          groupName={getReplaceSwatchs.groupName}
          subGroupName={getReplaceSwatchs.subGroupName}
          selctedSwatch={selctedSwatch}
         
          palletUrl={palletUrl}
          allSegmentAnnotation={getReplaceSwatchs.segmentationIntValue}
          
          resetWallSwatch={handleResetswatch1}
          />
        }
    
    </div>
  )
}

export default ReplaceSwatchHome